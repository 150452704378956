/* eslint-disable @typescript-eslint/ban-ts-comment */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/symbol';
import React from 'react';
import ReactDOM from 'react-dom';
import axe from 'react-axe';
import { ThemeProvider } from '@material-ui/core';
import { datadogRum } from '@datadog/browser-rum';
import { pteREDACTyl } from './services/dataDogRedactService';

import './index.css';
import { defaultTheme } from './styles/defaultTheme';
import App from './App';

if (process.env.NODE_ENV === 'development') {
  axe(React, ReactDOM, 1000);
}

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: window.env.DATADOG_APP_ID,
    clientToken: window.env.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'auto-site-provisioning',
    env: window.env.DATADOG_ENVIRONMENT,
    sampleRate: window.env.DATADOG_SAMPLE_RATE,
    // Temporary until we figure out what interactions are allowed
    trackInteractions: false,
    beforeSend: pteREDACTyl,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
