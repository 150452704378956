enum LanguageCode {
  English = 'en',
  Spanish = 'es',
}
export default LanguageCode;

export function toLanguageCodeOrDefault(lang: string): LanguageCode {
  const spanishLangRegex = new RegExp(`^es-.*$|^es$`);

  if (spanishLangRegex.test(lang)) {
    return LanguageCode.Spanish;
  }

  return LanguageCode.English;
}
