import React, { useState, useRef, MouseEvent } from 'react';
import { renderToString } from 'react-dom/server';

import { ThemeProvider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

import { Localizer } from '../Localizer/Localizer';
import * as localizationKeys from '../../services/localizationKeys';

import { Link } from '../Link/Link';
import { Typography } from '../Typography/Typography';
import { defaultTheme } from '../../styles/defaultTheme';
import { Button } from '../Button/Button';

const useNotYouStyle = makeStyles(() => ({
  popover: {
    zIndex: 1,
    whiteSpace: 'pre-wrap',
  },
  popoverContent: {
    padding: defaultTheme.spacing(2),
    width: 300,
  },
  popoverWrapper: {
    marginLeft: '16em',
    [defaultTheme.breakpoints.down('sm')]: {
      marginLeft: '6.7em',
    },
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const NotYou = () => {
  const classes = useNotYouStyle();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const open = Boolean(anchorEl);

  const id = open ? 'not-you-popover' : undefined;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div ref={popoverRef}>
      <Button priority="tertiary" aria-describedby={id} onClick={handleClick} size="compact">
        (<Localizer translation={localizationKeys.SignUpForm_NotYou} />)
      </Button>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={popoverRef.current}
        disablePortal={true}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        data-testid="notYou_popover"
      >
        <Paper className={classes.popoverContent}>
          <Typography variant="h6">
            <strong>
              <Localizer translation={localizationKeys.SignUpForm_NotYou} />
            </strong>
          </Typography>
          <Typography variant="subtitle2">
            <Localizer
              translation={localizationKeys.SignUpForm_NotYouModalCopy}
              trustedHtml={{
                phoneNumber: renderToString(
                  <ThemeProvider theme={defaultTheme}>
                    <Link href="tel:18007495104">1-800-749-5104</Link>
                  </ThemeProvider>,
                ),
                email: renderToString(
                  <ThemeProvider theme={defaultTheme}>
                    <Link href="mailto:support@powerdms.com">support@powerdms.com</Link>
                  </ThemeProvider>,
                ),
              }}
            />
          </Typography>
        </Paper>
      </Popover>
    </div>
  );
};
