import * as React from 'react';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

import * as localizationKeys from '../../services/localizationKeys';

import { Localizer } from '../Localizer/Localizer';
import { Palette } from '../../styles/palette';
import { Typography } from '../Typography/Typography';
import { TDefaultTheme } from '../../styles/defaultTheme';

export type TPasswordRequirementsProps = {
  isIn?: boolean;
  eightCharsOrMore?: boolean;
  upperAndLowercase?: boolean;
  oneNumberOrMore?: boolean;
  noPersonalInfo?: boolean;
};
type TClassKeys =
  | 'root'
  | 'requirementsContainer'
  | 'eightCharsOrMore'
  | 'upperAndLowercase'
  | 'oneNumberOrMore'
  | 'noPersonalInfo'
  | 'icon';
type TRequirementNames = Extract<keyof TPasswordRequirementsProps, TClassKeys>;

/* istanbul ignore next */
const useStyles = makeStyles<TDefaultTheme, TPasswordRequirementsProps, TClassKeys>((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  requirementsContainer: {
    '& > *:not(:last-of-type)': {
      marginBottom: theme.spacing(1),
    },
  },
  icon: {
    verticalAlign: 'bottom',
    fontSize: 16,
  },
  eightCharsOrMore: {
    color: (props) => (props.eightCharsOrMore ? Palette.positive : Palette.danger),
  },
  upperAndLowercase: {
    color: (props) => (props.upperAndLowercase ? Palette.positive : Palette.danger),
  },
  oneNumberOrMore: {
    color: (props) => (props.oneNumberOrMore ? Palette.positive : Palette.danger),
  },
  noPersonalInfo: {
    color: (props) => (props.noPersonalInfo ? Palette.positive : Palette.danger),
  },
}));

const PasswordRequirements: React.FC<TPasswordRequirementsProps> = (props) => {
  const styles = useStyles(props);

  return (
    <Collapse className={styles.root} in={props.isIn}>
      <div
        id="passwordRequirements"
        className={styles.requirementsContainer}
        role="region"
        aria-live="assertive"
        data-testid="passwordRequirements"
      >
        <Typography variant="body1" aria-hidden={props.eightCharsOrMore}>
          {getIcon('eightCharsOrMore')}
          &nbsp;
          <Localizer translation={localizationKeys.SignUpForm_EightCharsOrMore} />
        </Typography>
        <Typography variant="body1" aria-hidden={props.upperAndLowercase}>
          {getIcon('upperAndLowercase')}
          &nbsp;
          <Localizer translation={localizationKeys.SignUpForm_UpperAndLowercase} />
        </Typography>
        <Typography variant="body1" aria-hidden={props.oneNumberOrMore}>
          {getIcon('oneNumberOrMore')}
          &nbsp;
          <Localizer translation={localizationKeys.SignUpForm_OneNumberOrMore} />
        </Typography>
        <Typography variant="body1" aria-hidden={props.noPersonalInfo}>
          {getIcon('noPersonalInfo')}
          &nbsp;
          <Localizer translation={localizationKeys.SignUpForm_NoPersonalInfo} />
        </Typography>
      </div>
    </Collapse>
  );

  function getIcon(requirement: TRequirementNames) {
    const className = `${styles.icon} ${styles[requirement]} ${requirement}StatusIcon`;
    const Icon = props[requirement] ? DoneIcon : CloseIcon;

    return <Icon className={className} />;
  }
};

export { PasswordRequirements };
