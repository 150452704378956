import { sprintf } from 'sprintf-js';
import LanguageCode, { toLanguageCodeOrDefault } from '../models/languageCode';

interface LocalStrings {
  [id: string]: string;
}

export type TTranslation = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (...args: any): any;
  key: string;
};

class LocalizationService {
  private static isLoaded = false;

  private static localStrings: LocalStrings = {};

  public static currentLanguage: string;

  public static loadLanguage(language: string): Promise<void> {
    const lang = toLanguageCodeOrDefault(language);

    LocalizationService.currentLanguage = lang;

    return this.getTranslations(lang)
      .then((translations) => {
        Object.keys(translations).forEach((key) => {
          LocalizationService.localStrings[key] = translations[key];
        });
        LocalizationService.isLoaded = true;
      })
      .catch((err) => {
        console.error('Failed to get local strings.', err);
      });
  }

  private static getTranslations(lang: LanguageCode): Promise<LocalStrings> {
    return fetch(`locales/translations.${lang}.json`)
      .then((response) => response.json())
      .then((obj) => obj as LocalStrings);
  }

  public static localize = <T extends TTranslation>(
    translation: T,
    args: InstanceType<T>,
  ): string => {
    if (!LocalizationService.isLoaded) {
      return '';
    }

    if (translation && args && Object.keys(args).length > 0) {
      return sprintf(LocalizationService.localStrings[translation.key], args);
    }
    if (translation) {
      return LocalizationService.localStrings[translation.key];
    }
    return '';
  };

  public localize: <T extends TTranslation>(translation: T, args: InstanceType<T>) => string;

  constructor() {
    this.localize = LocalizationService.localize;
  }
}

export default LocalizationService;
