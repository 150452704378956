import { TGainsightPxEvent } from '../models/TGainsightPxEvent';
import LocalizationService from './localizationService';

export function identifyUserInGainsightPx(uniqueId: string): void {
  if (window.aptrinsic === undefined || window.aptrinsic === null) {
    return;
  }

  // This will give us "dev," "qa2," "env12," etc., or "powerdms" for prod.
  const env = window.env.POWERDMS_BASE_URL.split('//')[1].split('.')[0] ?? 'dev';

  /**
   * This is the script used for GainsightPx to identify a user. The id property is the only default
   * property used and the others are custom properties, some of which are used for GainsightPx to
   * integrate with SalesForce to sync data that is stored within SalesForce and others that are
   * used for the purpose of providing pertinent information to those controlling the Gainsight
   * experience on what "journey" needs to be displayed. The id needs to be unique across all
   * environments and sites.
   *
   * https://support.gainsight.com/PX/API_for_Developers/01About/Track_your_Users_and_Accounts#The_Identify_Call
   */
  window.aptrinsic('identify', {
    id: `${env}|${uniqueId}`,
    autoSiteProvisioning: true,
    userLanguage: LocalizationService.currentLanguage,
  });
}

export function sendToGainsightPx(event: TGainsightPxEvent): void {
  // The event name is sent to Gainsight as a separate argument; everything else in the event
  // can be passed along as an object containing details about the event.
  const { eventName, ...eventData } = event;
  window.aptrinsic('track', eventName, eventData);
}
