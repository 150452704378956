import React from 'react';
import { Link } from '../Link/Link';
import { Typography } from '../Typography/Typography';
import { makeStyles } from '@material-ui/core';
import { Palette } from '../../styles/palette';
import { TDefaultTheme } from '../../styles/defaultTheme';

import { Localizer } from '../Localizer/Localizer';
import * as localizationKeys from '../../services/localizationKeys';

const useStyles = makeStyles<TDefaultTheme>((theme: TDefaultTheme) => ({
  footer: {
    alignItems: 'center',
    backgroundColor: Palette.secondaryLighter,
    display: 'flex',
    flexShrink: 0,
    height: '4em',
    justifyContent: 'center',
    padding: theme.spacing(1.5),
    textAlign: 'center',
    width: '100%',
  },
}));

export const LegalFooter = (): JSX.Element => {
  const styles = useStyles();

  return (
    <footer className={styles.footer}>
      <Typography variant="body1">
        &copy; PowerDMS |
        <Link href="https://www.powerdms.com/terms-of-use/" target="_blank" rel="noopener">
          &nbsp; <Localizer translation={localizationKeys.TermsOfUse} />
        </Link>
        &nbsp;|
        <Link href="https://www.powerdms.com/privacy-policy/" target="_blank" rel="noopener">
          &nbsp; <Localizer translation={localizationKeys.PrivacyPolicy} />
        </Link>
      </Typography>
    </footer>
  );
};
