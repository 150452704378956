import * as React from 'react';
import { useField } from 'formik';

import { InputBaseProps } from '@material-ui/core/InputBase';
import { InputBase, NativeSelect } from '@material-ui/core';

import { InputWrapper } from '../InputWrapper/InputWrapper';

import { inputStyles } from '../../styles/inputStyles';

export type TDropdownFieldProps = Omit<
  InputBaseProps,
  'classes' | 'color' | 'defaultValue' | 'inputComponent'
> &
  /**
   * These props need to be required because the id is used for accessibility, and value is
   * required to make a controlled input
   */
  Required<Pick<InputBaseProps, 'id' | 'value'>> & {
    helperText?: string;
    label: string | React.ReactElement;
  } & { options: Array<{ key: string; label: string; value: string | number }> };

export function DropdownField(props: TDropdownFieldProps): JSX.Element {
  const {
    name = '',
    id,
    label,
    required,
    helperText,
    error,
    disabled,
    options,
    inputProps,
    ...rest
  } = props;

  const [field] = useField(name);

  const styles = inputStyles(props);

  return (
    <InputWrapper
      required={required}
      id={id}
      label={label}
      helperText={helperText}
      error={error}
      disabled={disabled}
      name={name}
    >
      <NativeSelect
        id={id}
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        input={
          <InputBase
            classes={{
              root: styles.input,
              error: styles.error,
              disabled: styles.disabled,
              inputMultiline: styles.inputMultiline,
              focused: styles.focused,
            }}
            inputProps={{
              'aria-describedby': helperText ? `${id}Helper` : undefined,
              'aria-invalid': error,
              'aria-errormessage': helperText ? `${id}Helper` : undefined,
              'aria-controls': helperText ? `${id}Helper` : undefined,
              ...inputProps,
            }}
            {...field}
            {...rest}
          />
        }
      >
        <option aria-label="None" value="" />
        {options.map(({ key, label: optionLabel, value }) => (
          <option key={key} value={value}>
            {optionLabel}
          </option>
        ))}
      </NativeSelect>
    </InputWrapper>
  );
}
