import * as React from 'react';
import { Typography } from '../Typography/Typography';
import { Palette } from '../../styles/palette';

type TVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type TTextAlignment = 'center' | 'left' | 'right';

export type TTitleProps = {
  variant?: TVariant;
  color?: Palette;
  align?: TTextAlignment;
  component?: TVariant;
  children: React.ReactNode;
};

export const Title: React.FC<TTitleProps> = (props) => {
  const { children, variant = 'h1', ...rest } = props;

  return (
    <Typography data-testid="title" variant={variant} bold {...rest}>
      {children}
    </Typography>
  );
};
