import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

import { ReactComponent as GoogleBadgeEn } from '../../assets/images/google-badge-en.svg';
import { ReactComponent as GoogleBadgeEs } from '../../assets/images/google-badge-es.svg';

import LocalizationService from '../../services/localizationService';
import * as localizationKeys from '../../services/localizationKeys';

type TGooglePlayStoreBadgeProps = {
  trackingLink?: string;
};

const useStyles = makeStyles(() => ({
  largeIcon: {
    fontSize: '9rem',
    fill: 'inherit',
    height: 'inherit',
    width: 'inherit',
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const GooglePlayStoreBadge = (props: TGooglePlayStoreBadgeProps) => {
  const styles = useStyles();

  const baseLink = 'https://play.google.com/store/apps/details?id=com.powerdms.android.powerdms';
  const link = props.trackingLink ? `${baseLink}${props.trackingLink}` : baseLink;
  const isSpanish = LocalizationService.currentLanguage === 'es';

  return (
    <a href={link}>
      <SvgIcon
        className={styles.largeIcon}
        color="inherit"
        viewBox="0 0 135 40.1"
        titleAccess={LocalizationService.localize(localizationKeys.DownloadApp_GoogleBadgeAlt, {})}
        data-testid={isSpanish ? 'google_es' : 'google_en'}
        component={isSpanish ? GoogleBadgeEs : GoogleBadgeEn}
      />
    </a>
  );
};
