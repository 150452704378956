import * as React from 'react';
import './IntegratedContent.css';
import * as Keys from '../../services/localizationKeys';
import { Localizer } from '../Localizer/Localizer';

type TIntegratedContentProps = {
  contentParentId: string;
};

export function IntegratedContent({ contentParentId }: TIntegratedContentProps): JSX.Element {
  return (
    <div id={`integrated_content_${contentParentId}`} data-testid={`auto_site_${contentParentId}`}>
      <div id={contentParentId}>
        {/* UI rendered by the Integrated Content app should appear as a child of this node. */}
        <div data-automation-id="ASPSignupFormSidebar">
          <div
            className="ASPSignupFormSidebar-integratedContent-jss1 ASPSignupFormSidebar-integratedContent-jss2"
            data-automation-id="richTextItem_0"
          >
            <p
              className="ASPSignupFormSidebar-integratedContent-MuiTypography-root ASPSignupFormSidebar-integratedContent-jss4 ASPSignupFormSidebar-integratedContent-jss10 ASPSignupFormSidebar-integratedContent-jss5 ASPSignupFormSidebar-integratedContent-MuiTypography-body1 ASPSignupFormSidebar-integratedContent-MuiTypography-displayInline"
              data-automation-id="richText_typography"
            >
              <Localizer translation={Keys.MarketingAside_DefaultProductDescription} />
            </p>
          </div>
          <ul className="ASPSignupFormSidebar-integratedContent-jss11">
            <li className="ASPSignupFormSidebar-integratedContent-jss12">
              <p className="ASPSignupFormSidebar-integratedContent-MuiTypography-root ASPSignupFormSidebar-integratedContent-jss14 ASPSignupFormSidebar-integratedContent-MuiTypography-body1">
                <svg
                  className="ASPSignupFormSidebar-integratedContent-MuiSvgIcon-root ASPSignupFormSidebar-integratedContent-jss13"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                </svg>
                <Localizer translation={Keys.MarketingAside_DefaultListItem1PrimaryContent} />
              </p>
              <div className="ASPSignupFormSidebar-integratedContent-jss15">
                <div
                  className="ASPSignupFormSidebar-integratedContent-jss1 ASPSignupFormSidebar-integratedContent-jss17"
                  data-automation-id="richTextItem_0"
                >
                  <p
                    className="ASPSignupFormSidebar-integratedContent-MuiTypography-root ASPSignupFormSidebar-integratedContent-jss4 ASPSignupFormSidebar-integratedContent-jss18 ASPSignupFormSidebar-integratedContent-jss5 ASPSignupFormSidebar-integratedContent-MuiTypography-body1 ASPSignupFormSidebar-integratedContent-MuiTypography-displayInline"
                    data-automation-id="richText_typography"
                  >
                    <Localizer translation={Keys.MarketingAside_DefaultListItem1SecondaryContent} />
                  </p>
                </div>
              </div>
            </li>
            <li className="ASPSignupFormSidebar-integratedContent-jss12">
              <p className="ASPSignupFormSidebar-integratedContent-MuiTypography-root ASPSignupFormSidebar-integratedContent-jss14 ASPSignupFormSidebar-integratedContent-MuiTypography-body1">
                <svg
                  className="ASPSignupFormSidebar-integratedContent-MuiSvgIcon-root ASPSignupFormSidebar-integratedContent-jss13"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                </svg>
                <Localizer translation={Keys.MarketingAside_DefaultListItem2PrimaryContent} />
              </p>
              <div className="ASPSignupFormSidebar-integratedContent-jss15">
                <div
                  className="ASPSignupFormSidebar-integratedContent-jss1 ASPSignupFormSidebar-integratedContent-jss20"
                  data-automation-id="richTextItem_0"
                >
                  <p
                    className="ASPSignupFormSidebar-integratedContent-MuiTypography-root ASPSignupFormSidebar-integratedContent-jss4 ASPSignupFormSidebar-integratedContent-jss21 ASPSignupFormSidebar-integratedContent-jss5 ASPSignupFormSidebar-integratedContent-MuiTypography-body1 ASPSignupFormSidebar-integratedContent-MuiTypography-displayInline"
                    data-automation-id="richText_typography"
                  >
                    <Localizer translation={Keys.MarketingAside_DefaultListItem2SecondaryContent} />
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <div
            className="ASPSignupFormSidebar-integratedContent-jss1 ASPSignupFormSidebar-integratedContent-jss22"
            data-automation-id="richTextItem_0"
          >
            <p
              className="ASPSignupFormSidebar-integratedContent-MuiTypography-root ASPSignupFormSidebar-integratedContent-jss4 ASPSignupFormSidebar-integratedContent-jss23 ASPSignupFormSidebar-integratedContent-jss5 ASPSignupFormSidebar-integratedContent-MuiTypography-body1 ASPSignupFormSidebar-integratedContent-MuiTypography-displayInline"
              data-automation-id="richText_typography"
            >
              &quot;
              <Localizer translation={Keys.MarketingAside_DefaultProductQuote} />
              &quot;
            </p>
          </div>
          <div
            className="ASPSignupFormSidebar-integratedContent-jss1 ASPSignupFormSidebar-integratedContent-jss22"
            data-automation-id="richTextItem_1"
          >
            <p
              className="ASPSignupFormSidebar-integratedContent-MuiTypography-root ASPSignupFormSidebar-integratedContent-jss4 ASPSignupFormSidebar-integratedContent-jss24 ASPSignupFormSidebar-integratedContent-jss5 ASPSignupFormSidebar-integratedContent-MuiTypography-body1 ASPSignupFormSidebar-integratedContent-MuiTypography-displayInline"
              data-automation-id="richText_typography"
            >
              <b>Ethan Dunn</b>
            </p>
          </div>
          <div
            className="ASPSignupFormSidebar-integratedContent-jss1 ASPSignupFormSidebar-integratedContent-jss22"
            data-automation-id="richTextItem_2"
          >
            <p
              className="ASPSignupFormSidebar-integratedContent-MuiTypography-root ASPSignupFormSidebar-integratedContent-jss4 ASPSignupFormSidebar-integratedContent-jss25 ASPSignupFormSidebar-integratedContent-jss5 ASPSignupFormSidebar-integratedContent-MuiTypography-body1 ASPSignupFormSidebar-integratedContent-MuiTypography-displayInline"
              data-automation-id="richText_typography"
            >
              <Localizer translation={Keys.MarketingAside_Customer} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
