import React, { useState, ChangeEvent } from 'react';
import { Field, FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Box from '@material-ui/core/Box';

import * as localizationKeys from '../../services/localizationKeys';
import {
  debouncedValidateSiteKeyAvailability,
  getErrorState,
  getHelperText,
} from '../../services/formValidationService';
import { Palette } from '../../styles/palette';

import { EmailLabel } from '../EmailLabel/EmailLabel';
import { FormRowGrid } from '../FormRowGrid/FormRowGrid';
import { Link } from '../Link/Link';
import { Localizer } from '../Localizer/Localizer';
import { PasswordRequirements } from '../PasswordRequirements/PasswordRequirements';
import { PasswordVisibilityAdornment } from '../PasswordVisibilityAdornment/PasswordVisibilityAdornment';
import { TSignupFormValues } from './SignupForm';
import { TextField } from '../TextField/TextField';
import { Typography } from '../Typography/Typography';
import { usePasswordRequirements } from '../../hooks/usePasswordRequirements';

type TSecondStepProps = {
  errors: FormikErrors<TSignupFormValues>;
  handleBlur: FormikHandlers['handleBlur'];
  handleChange: FormikHandlers['handleChange'];
  setValues: FormikHelpers<TSignupFormValues>['setValues'];
  siteKeyHelpers: {
    setSiteKeyAvailable: (value: boolean | undefined) => void;
    shouldShowSiteKeyAvailabilityIndicator: boolean | undefined;
    siteKeyAvailable: boolean | undefined;
    siteKeyHelperText: string;
    siteKeyIsError: boolean | undefined;
  };
  touched: FormikTouched<TSignupFormValues>;
  values: TSignupFormValues;
};

export function SecondStep(props: TSecondStepProps): JSX.Element {
  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    setValues,
    siteKeyHelpers: {
      siteKeyAvailable,
      setSiteKeyAvailable,
      siteKeyHelperText,
      siteKeyIsError,
      shouldShowSiteKeyAvailabilityIndicator,
    },
  } = props;

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const passwordRequirementsNotMet = usePasswordRequirements(values, setValues);

  const siteKeyLength = 25; // value from SalesForce and enforced by API
  const userNameLength = 255; // value from SalesForce and enforced by API
  const passwordLength = 4096; // value from SalesForce and enforced by API

  const notEdge = !(window.navigator.userAgent.indexOf('Edg') > -1);

  return (
    <div data-testid="signUp_secondStep">
      <FormRowGrid>
        <Grid container item xs={12}>
          <TextField
            id="siteKey"
            label={<Localizer translation={localizationKeys.SignUpForm_SiteKey} />}
            type="text"
            name="siteKey"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
              debouncedValidateSiteKeyAvailability(e.target.value, setSiteKeyAvailable);
            }}
            onBlur={handleBlur}
            value={values.siteKey}
            error={siteKeyIsError}
            helperText={siteKeyHelperText}
            inputProps={{ maxLength: siteKeyLength }}
            endAdornment={
              shouldShowSiteKeyAvailabilityIndicator &&
              (siteKeyAvailable ? (
                <Box mr={1} display="flex" data-testid="doneIcon">
                  <DoneIcon htmlColor={Palette.positive} fontSize="small" />
                </Box>
              ) : siteKeyAvailable === false ? (
                <Box mr={1} display="flex" data-testid="closeIcon">
                  <CloseIcon htmlColor={Palette.danger} fontSize="small" />
                </Box>
              ) : undefined)
            }
            autoFocus
            required
          />
        </Grid>
      </FormRowGrid>

      <FormRowGrid>
        <Grid container item xs={12}>
          <TextField
            id="email"
            label={<EmailLabel />}
            type="email"
            name="email"
            value={values.email}
            /**
             * The email field is readonly. If users only see an error if they touch the field, they
             * are likely to miss the error. So, for this field only, we will override the touched
             * value that the form calculates and always indicate it as true so that any errors show
             * up without user interaction.
             */
            error={getErrorState('email', errors, { ...touched, email: true })}
            helperText={getHelperText('email', errors, { ...touched, email: true })}
            readOnly
          />
        </Grid>
      </FormRowGrid>

      <FormRowGrid>
        <Grid container item xs={12}>
          <TextField
            id="username"
            label={<Localizer translation={localizationKeys.SignUpForm_CreateUsername} />}
            type="text"
            name="username"
            autoComplete="username"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.username}
            error={getErrorState('username', errors, touched)}
            helperText={getHelperText('username', errors, touched)}
            inputProps={{ maxLength: userNameLength }}
            required
          />
        </Grid>
      </FormRowGrid>

      <FormRowGrid>
        <Grid container item xs={12}>
          <TextField
            id="password"
            label={<Localizer translation={localizationKeys.SignUpForm_CreatePassword} />}
            type={passwordVisible ? 'text' : 'password'}
            name="password"
            autoComplete="new-password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={getErrorState('password', errors, touched)}
            endAdornment={
              notEdge ? (
                <PasswordVisibilityAdornment
                  visible={passwordVisible}
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  onMouseDown={(event) => event.preventDefault()}
                  data-testid="password-adornment"
                />
              ) : null
            }
            inputProps={{
              'aria-errormessage': 'passwordRequirements',
              'aria-describedby': 'passwordRequirements',
              maxLength: passwordLength,
            }}
            data-testid="signUp_password"
            required
          />
          <PasswordRequirements
            isIn={passwordRequirementsNotMet}
            eightCharsOrMore={values.eightCharsOrMore}
            oneNumberOrMore={values.oneNumberOrMore}
            upperAndLowercase={values.upperAndLowercase}
            noPersonalInfo={values.noPersonalInfo}
          />
        </Grid>
      </FormRowGrid>

      <FormRowGrid>
        <Box display="flex" alignItems="baseline" mt={3} mb={2} width="100%">
          <Grid container item xs={1}>
            <Field id="terms" type="checkbox" name="terms" required />
          </Grid>

          <Grid container item xs={11}>
            <label htmlFor="terms">
              <Typography variant="body1">
                <Localizer translation={localizationKeys.SignUpForm_TermsLabelStart} />
                &nbsp;
                <Link href="https://www.powerdms.com/terms-of-use/" target="_blank" rel="noopener">
                  <Localizer translation={localizationKeys.TermsOfUse} />
                </Link>
                &nbsp;
                <Localizer translation={localizationKeys.SignUpForm_TermsLabelEnd} />
              </Typography>
            </label>
          </Grid>
        </Box>
      </FormRowGrid>
    </div>
  );
}
