import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

import { defaultTheme } from '../../styles/defaultTheme';

type FormRowGridProps = { children: React.ReactNode };

const useStyles = makeStyles<typeof defaultTheme, FormRowGridProps>((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}));

export function FormRowGrid(props: FormRowGridProps): JSX.Element {
  const { children } = props;
  const { root } = useStyles(props);
  return (
    <Grid
      classes={{ root }}
      container
      spacing={1}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      {children}
    </Grid>
  );
}
