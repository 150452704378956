import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core';

import { LegalFooter } from '../LegalFooter/LegalFooter';
import { IntegratedContent } from '../IntegratedContent/IntegratedContent';
import { Typography } from '../Typography/Typography';
import { PowerDMSLogo } from '../PowerDMSLogo/PowerDMSLogo';
import { SignupForm, TSignupFormValues } from '../SignupForm/SignupForm';

import { Palette } from '../../styles/palette';
import { TDefaultTheme } from '../../styles/defaultTheme';
import { identifyUserInGainsightPx } from '../../services/gainsightPxService';

const initialValues: TSignupFormValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  orgName: '',
  orgState: '',
  numOfEmployees: 0,
  industry: '',
  timezone: '',
  siteKey: '',
  email: '',
  username: '',
  password: '',
  terms: false,
  eightCharsOrMore: false,
  upperAndLowercase: false,
  oneNumberOrMore: false,
  noPersonalInfo: false,
};

const useStyles = makeStyles((theme: TDefaultTheme) => ({
  aside: {
    height: '100%',
    margin: theme.spacing(4),
  },
  asideColumn: {
    // Changed secondaryLighter to accommodate prototype. TBD on whether or not we keep this
    backgroundColor: Palette.secondaryLighter,
    borderRight: `1px solid ${Palette.gray}`,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  header: {
    backgroundColor: Palette.secondaryLighter,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  formColumn: {
    alignItems: 'center',
    backgroundColor: Palette.white,
    display: 'flex',
    flexFlow: 'column',
    minHeight: '100%',
  },
  marketingContent: {
    flexDirection: 'column',
    flex: '1 0 auto',
  },
  marketingList: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  main: {
    marginTop: theme.spacing(7),
    width: 500,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'column',
      margin: theme.spacing(3),
      maxWidth: 350,
    },
  },
}));

export const SignupPage = (): JSX.Element => {
  const styles = useStyles();

  const urlParams = new URLSearchParams(window.location.search);
  initialValues.email = urlParams.get('email') || '';
  const invitationId = urlParams.get('invitation') || '';
  const invitationPublisher = urlParams.get('publisher') || '';

  React.useEffect(() => {
    /**
     * In case we don't have an invitation ID, we still want something unique -- so, a timestamp
     * should suffice
     */
    const uniqueId = invitationId === '' ? new Date().toISOString() : invitationId;
    identifyUserInGainsightPx(uniqueId);
  }, []);

  return (
    <Grid container spacing={0}>
      <Hidden mdDown>
        <Grid item lg={3} className={styles.asideColumn}>
          <Box display="flex" flexDirection="column" flex="1 1 auto">
            <aside className={styles.aside}>
              <Typography variant="srOnly" id="logoAlt">
                PowerDMS
              </Typography>
              <PowerDMSLogo aria-labelledby="logoAlt" />
              <IntegratedContent contentParentId="dynamicMarketingContent" />
            </aside>
          </Box>
          <LegalFooter />
        </Grid>
      </Hidden>
      <Box flex={1}>
        <Grid item xs={12} lg={12} className={styles.formColumn}>
          <Hidden lgUp>
            <header className={styles.header}>
              <Typography variant="srOnly" id="mobileLogoAlt">
                PowerDMS
              </Typography>
              <PowerDMSLogo aria-labelledby="mobileLogoAlt" />
            </header>
          </Hidden>
          <main className={styles.main}>
            <SignupForm
              initialValues={initialValues}
              invitationPublisher={invitationPublisher}
              invitationId={invitationId}
            />
          </main>
          <Hidden lgUp>
            <LegalFooter />
          </Hidden>
        </Grid>
      </Box>
    </Grid>
  );
};
