import * as React from 'react';
import { useField, FieldConfig } from 'formik';

import { InputBaseProps } from '@material-ui/core/InputBase';
import { InputBase } from '@material-ui/core';

import { inputStyles } from '../../styles/inputStyles';

import { InputWrapper } from '../InputWrapper/InputWrapper';

export type TTextFieldProps = Omit<
  InputBaseProps,
  'classes' | 'color' | 'defaultValue' | 'inputComponent'
> & /**
 * These props need to be required because the id is used for accessibility, and value is
 * required to make a controlled input
 */ {
  isMultilineResizable?: boolean;
  helperText?: string;
  label: string | React.ReactElement;
  validate?: FieldConfig['validate'];
};

export function TextField(props: TTextFieldProps): JSX.Element {
  const {
    name = '',
    id,
    helperText,
    error,
    label,
    required,
    disabled,
    inputProps,
    validate,
    ...rest
  } = props;

  const [field] = useField({ name, validate });

  const styles = inputStyles(props);

  return (
    <InputWrapper
      required={required}
      id={id}
      label={label}
      disabled={disabled}
      error={error}
      helperText={helperText}
      name={name}
    >
      <InputBase
        id={id}
        classes={{
          root: styles.input,
          error: styles.error,
          disabled: styles.disabled,
          inputMultiline: styles.inputMultiline,
          focused: styles.focused,
        }}
        inputProps={{
          'aria-describedby': helperText ? `${id}Helper` : undefined,
          'aria-invalid': error,
          'aria-errormessage': helperText ? `${id}Helper` : undefined,
          'aria-controls': helperText ? `${id}Helper` : undefined,
          ...inputProps,
        }}
        {...field}
        {...rest}
      />
    </InputWrapper>
  );
}
