import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import * as localizationKeys from '../../services/localizationKeys';
import { Localizer } from '../Localizer/Localizer';

import { Typography, TTypographyProps } from '../Typography/Typography';
import { Link } from '../Link/Link';

import { TDefaultTheme } from '../../styles/defaultTheme';

type TPageFooterProps = Pick<TTypographyProps, 'color'> & {
  linkMonochromeColor?: 'white' | 'black';
};

const useStyle = makeStyles<TDefaultTheme, TPageFooterProps>((theme: TDefaultTheme) => ({
  footer: {
    flex: '0 0 auto',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

export const PageFooter: FC<TPageFooterProps> = (props) => {
  const classes = useStyle(props);
  const currentYear = new Date().getFullYear().toString();

  return (
    <footer className={classes.footer}>
      <Typography variant="caption" color={props.color}>
        <Link
          href="https://www.powerdms.com"
          id="footer__visitPdmsLink"
          monochrome={props.linkMonochromeColor}
        >
          <Localizer translation={localizationKeys.DownloadApp_VisitPowerDMS} />
        </Link>
      </Typography>
      <Typography variant="caption" color={props.color} display="block">
        <Localizer translation={localizationKeys.DownloadApp_Copy} trustedHtml={{ currentYear }} />
      </Typography>
    </footer>
  );
};
