const escapedHtmlChars: { [key: string]: string } = {
  '&': '&amp;',
  '>': '&gt;',
  '<': '&lt;',
  '"': '&quot;',
  "'": '&apos;',
};

const unsafeHtmlCharsRegex = new RegExp(`[${Object.keys(escapedHtmlChars).join('')}]`, 'g');

export function escapeHtml(str: string): string {
  return str === undefined || str === null
    ? ''
    : str.replace(unsafeHtmlCharsRegex, (unsafeChar) => escapedHtmlChars[unsafeChar]);
}
