import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

import { ReactComponent as PowerDMSLogoBlue } from '../../assets/images/powerdms-logo-blue.svg';

import LocalizationService from '../../services/localizationService';
import * as localizationKeys from '../../services/localizationKeys';

/* istanbul ignore next */
const useStyles = makeStyles(
  () => ({
    largeIcon: {
      fontSize: '7rem',
    },
  }),
  {
    index: 0,
  },
);

export const PowerDMSLogo = (props: SvgIconProps): ReactElement => {
  const styles = useStyles();
  return (
    <SvgIcon
      {...props}
      className={styles.largeIcon}
      viewBox="0 0 864 133.3"
      titleAccess={LocalizationService.localize(localizationKeys.PowerDMSLogo, {})}
      data-testid="dms-logo"
      component={PowerDMSLogoBlue}
    />
  );
};
