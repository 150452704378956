import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { Palette } from '../../styles/palette';

export type TEllipsisLoaderProps = {
  horizontalPosition?: 'left' | 'center' | 'right';
  verticalPosition?: 'top' | 'bottom' | 'middle';
};

const useStyles = makeStyles<unknown, TEllipsisLoaderProps>(() => ({
  ellipsisLoader: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: ({ verticalPosition }): string => {
      switch (verticalPosition) {
        case 'bottom':
          return 'flex-end';
        case 'top':
          return 'flex-start';
        default:
          return 'center';
      }
    },
    justifyContent: ({ horizontalPosition }): string => {
      switch (horizontalPosition) {
        case 'left':
          return 'flex-start';
        case 'right':
          return 'flex-end';
        default:
          return 'center';
      }
    },
  },
  square: {
    animation: '$blink 400ms infinite',
    backgroundColor: Palette.primaryLight,
    borderRadius: 2,
    height: 11,
    margin: '0px 2.5px',
    width: 11,
    '&:nth-child(1)': {
      marginLeft: 0,
    },
    '&:nth-child(2)': {
      animationDelay: '80ms',
    },
    '&:nth-child(3)': {
      animationDelay: '160ms',
      marginRight: 0,
    },
  },
  squareRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  '@keyframes blink': {
    '0%, 65%': {
      backgroundColor: Palette.primaryLight,
    },
    '66%, 100%': {
      backgroundColor: Palette.primary,
    },
  },
}));

export const EllipsisLoader: React.FC<TEllipsisLoaderProps> = (
  props: TEllipsisLoaderProps,
): JSX.Element => {
  const styles = useStyles(props);

  return (
    <div className={styles.ellipsisLoader} data-testid="ellipsisLoader">
      <div className={styles.squareRow}>
        <div className={styles.square} />
        <div className={styles.square} />
        <div className={styles.square} />
      </div>
    </div>
  );
};
