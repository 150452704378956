import { FormikErrors, FormikTouched } from 'formik';
import { debounce } from 'lodash';

import * as localizationKeys from './localizationKeys';
import LocalizationService from './localizationService';
import { isSiteKeyAvailable } from './siteService';

import { TSignupFormValues } from '../components/SignupForm/SignupForm';
import { TPasswordRequirements } from '../hooks/usePasswordRequirements';

export function validatePhoneNumber(phoneNumber: string): string | undefined {
  if (!phoneNumber) {
    return LocalizationService.localize(localizationKeys.SignUpForm_RequiredField, {});
  }

  // We want to test that they have at least 10 digits
  const numberRegex = new RegExp(/[0-9]/, 'g');

  // And they are allowed to use some special characters
  const allowedCharactersRegex = new RegExp(/^[( )0-9\-x+]*$/);

  const amountOfNumbersInString = phoneNumber.match(numberRegex)?.length ?? 0;

  const hasEnoughNumbers = amountOfNumbersInString >= 10;
  const passesCharacterRegex = allowedCharactersRegex.test(phoneNumber);

  if (!hasEnoughNumbers || !passesCharacterRegex) {
    return LocalizationService.localize(localizationKeys.SignUpForm_PhoneNumberInvalid, {});
  }
}

export function validateFirstStep(values: TSignupFormValues): FormikErrors<TSignupFormValues> {
  const errors: FormikErrors<TSignupFormValues> = {};

  if (!values.firstName) {
    errors.firstName = LocalizationService.localize(localizationKeys.SignUpForm_RequiredField, {});
  }

  if (!values.lastName) {
    errors.lastName = LocalizationService.localize(localizationKeys.SignUpForm_RequiredField, {});
  }

  const phoneNumberErr = validatePhoneNumber(values.phoneNumber);

  if (phoneNumberErr !== undefined) {
    errors.phoneNumber = phoneNumberErr;
  }

  if (!values.orgName) {
    errors.orgName = LocalizationService.localize(localizationKeys.SignUpForm_RequiredField, {});
  }

  if (!values.orgState) {
    errors.orgState = LocalizationService.localize(localizationKeys.SignUpForm_RequiredField, {});
  }

  const numOfEmployeesErr = validateNumOfEmployees(values.numOfEmployees);

  if (numOfEmployeesErr !== undefined) {
    errors.numOfEmployees = numOfEmployeesErr;
  }

  if (!values.industry) {
    errors.industry = LocalizationService.localize(localizationKeys.SignUpForm_RequiredField, {});
  }

  if (!values.timezone) {
    errors.timezone = LocalizationService.localize(localizationKeys.SignUpForm_RequiredField, {});
  }

  return errors;
}

function validateNumOfEmployees(numOfEmployees: number | undefined): string | undefined {
  if (numOfEmployees === undefined || numOfEmployees === null) {
    return LocalizationService.localize(localizationKeys.SignUpForm_RequiredField, {});
  }

  if (numOfEmployees < 1 || numOfEmployees > 10000000) {
    return LocalizationService.localize(
      localizationKeys.SignUpForm_NumberOfEmployeesValidation,
      {},
    );
  }
}

function validateSiteKey(siteKey: string): string | undefined {
  if (!siteKey) {
    return LocalizationService.localize(localizationKeys.SignUpForm_RequiredField, {});
  }

  const siteKeySupportedRegex = new RegExp(/^\d*[a-zA-Z][a-zA-Z0-9]*$/);

  if (!siteKeySupportedRegex.test(siteKey)) {
    return LocalizationService.localize(localizationKeys.SignUpForm_AlphanumericSiteKey, {});
  }
}

export function validateSecondStep(values: TSignupFormValues): FormikErrors<TSignupFormValues> {
  /**
   * If you create a property for a key, even if the value is undefined, that will automatically
   * count as an error when formik validates :(
   */
  const errors: FormikErrors<TSignupFormValues & TPasswordRequirements> = {
    ...validatePasswordRequirements({
      eightCharsOrMore: values.eightCharsOrMore,
      upperAndLowercase: values.upperAndLowercase,
      oneNumberOrMore: values.oneNumberOrMore,
      noPersonalInfo: values.noPersonalInfo,
    }),
  };

  const siteKeyErr = validateSiteKey(values.siteKey);

  if (siteKeyErr !== undefined) {
    errors.siteKey = siteKeyErr;
  }

  const emailErr = validateEmail(values.email);

  if (emailErr !== undefined) {
    errors.email = emailErr;
  }

  if (!values.username) {
    errors.username = LocalizationService.localize(localizationKeys.SignUpForm_RequiredField, {});
  }

  if (!values.password) {
    errors.password = LocalizationService.localize(localizationKeys.SignUpForm_RequiredField, {});
  }

  if (!values.terms) {
    errors.terms = LocalizationService.localize(localizationKeys.SignUpForm_RequiredField, {});
  }

  return errors;
}

export function validatePasswordRequirements(
  passwordRequirements: TPasswordRequirements,
): FormikErrors<TPasswordRequirements> {
  const errors: FormikErrors<TPasswordRequirements> = {};

  if (!passwordRequirements.eightCharsOrMore) {
    errors.eightCharsOrMore = LocalizationService.localize(
      localizationKeys.SignUpForm_EightCharsOrMore,
      {},
    );
  }

  if (!passwordRequirements.noPersonalInfo) {
    errors.noPersonalInfo = LocalizationService.localize(
      localizationKeys.SignUpForm_NoPersonalInfo,
      {},
    );
  }

  if (!passwordRequirements.oneNumberOrMore) {
    errors.oneNumberOrMore = LocalizationService.localize(
      localizationKeys.SignUpForm_OneNumberOrMore,
      {},
    );
  }

  if (!passwordRequirements.upperAndLowercase) {
    errors.upperAndLowercase = LocalizationService.localize(
      localizationKeys.SignUpForm_UpperAndLowercase,
      {},
    );
  }

  return errors;
}

export async function validateSiteKeyAvailability(
  siteKey: string,
  setAvailable: (value: boolean | undefined) => void,
): Promise<string | void> {
  if (siteKey.length > 0) {
    setAvailable(await isSiteKeyAvailable(siteKey));
  }
}

function validateEmail(email: string): string | undefined {
  /**
   * Should match: stuff@stuff.alphabetical
   * From http://www.regular-expressions.info/email.html, a combination of the basic one + the
   * accepted characters from the more advanced one
   */
  const validEmailRegex = new RegExp(
    /^[A-Za-z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Za-z0-9.!#$%&'*+/=?^_`{|}~-]+\.[A-Za-z]+$/,
  );

  if (!email || !validEmailRegex.test(email)) {
    return LocalizationService.localize(localizationKeys.SignUpForm_EmailInvalid, {
      phoneNumber: '1-800-749-5104',
      email: 'support@powerdms.com',
    });
  }

  if (email.length > 80) {
    return LocalizationService.localize(localizationKeys.SignUpForm_EmailLengthInvalid, {
      phoneNumber: '1-800-749-5104',
      email: 'support@powerdms.com',
    });
  }
}

export const debouncedValidateSiteKeyAvailability = debounce(validateSiteKeyAvailability, 500);

export function getErrorState(
  key: keyof TSignupFormValues,
  errors: FormikErrors<TSignupFormValues>,
  touched: FormikTouched<TSignupFormValues>,
): boolean | undefined {
  return Boolean(errors[key]) && touched[key];
}

export function getHelperText(
  key: keyof TSignupFormValues,
  errors: FormikErrors<TSignupFormValues>,
  touched: FormikTouched<TSignupFormValues>,
): string {
  return touched[key] ? errors[key] ?? ' ' : ' ';
}
