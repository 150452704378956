import * as React from 'react';
import { useField } from 'formik';

import { InputBaseProps } from '@material-ui/core/InputBase';
import { FormLabel, FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { TDefaultTheme } from '../../styles/defaultTheme';
import { Palette } from '../../styles/palette';
import { Typography } from '../Typography/Typography';

type TInputWrapperProps = Omit<
  InputBaseProps,
  'classes' | 'color' | 'defaultValue' | 'inputComponent'
> & /**
 * These props need to be required because the id is used for accessibility, and value is
 * required to make a controlled input
 */ {
  children: React.ReactNode;
  helperText?: string;
  label: string | React.ReactElement;
} & { isMultilineResizable?: boolean };

const useStyles = makeStyles<TDefaultTheme, TInputWrapperProps>((theme) => ({
  label: {
    // @TODO: This one will stay as darkTextLight (.75)
    color: Palette.darkTextLight,
    flex: 'auto',
    lineHeight: 1.3,
    marginBottom: theme.spacing(0.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // Material changes the color in these states, so we need to override it.
    '&$disabled': {
      // @TODO: This will become new darkTextLighter (0.54)
      color: Palette.darkTextLighter,
    },
    '&$error, &$focused': {
      // This one will stay as darkTextLight (.75)
      color: Palette.darkTextLight,
    },
  },
  helperText: {
    display: (props) => (props.helperText ? 'block' : 'none'),
    // @TODO: replace this with the new 0.54 opacity color
    color: Palette.darkTextLight,
    transition: 'color 300ms cubic-bezier(0.86, 0.05, 0.14, 0.93)',
    '&$error': {
      color: Palette.danger,
    },
    // Material changes the color in this state, so we need to override it.
    '&$disabled': {
      // @TODO: replace this with the new 0.54 opacity color
      color: Palette.darkTextLight,
    },
  },
  asterisk: {
    color: Palette.danger,
    '&$error': {
      color: Palette.danger,
    },
  },
  error: {},
  focused: {},
  disabled: {},
}));

export function InputWrapper(props: TInputWrapperProps): JSX.Element {
  const { children, name = '', id, helperText, label, required, error, disabled } = props;

  const styles = useStyles(props);

  const [, meta] = useField(name);

  const roleFromHelperTextLength = helperText && helperText.length > 1 ? 'alert' : 'none';

  return (
    <FormControl
      required={required}
      error={Boolean((meta.touched && meta.error) || error)}
      disabled={disabled}
      fullWidth={true}
    >
      <FormLabel
        classes={{
          root: styles.label,
          error: styles.error,
          asterisk: styles.asterisk,
          focused: styles.focused,
          disabled: styles.disabled,
        }}
        htmlFor={id}
      >
        <Typography variant="body1" component="span">
          {label}
        </Typography>
      </FormLabel>
      {children}
      <FormHelperText
        classes={{ root: styles.helperText, error: styles.error, disabled: styles.disabled }}
        id={`${id}Helper`}
        role={roleFromHelperTextLength}
      >
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}
