import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

import { Localizer } from '../Localizer/Localizer';
import * as localizationKeys from '../../services/localizationKeys';

import { Typography } from '../Typography/Typography';
import { NotYou } from '../NotYou/NotYou';

const emailLabelStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  label: {
    flexGrow: 1,
    color: 'inherit',
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const EmailLabel = () => {
  const styles = emailLabelStyles();

  return (
    <Box className={styles.container}>
      <Typography className={styles.label} data-testid="email_label">
        <Localizer translation={localizationKeys.SignUpForm_WorkEmailAddress} />
      </Typography>
      <NotYou />
    </Box>
  );
};
