import React from 'react';
import { renderToString } from 'react-dom/server';
import { ThemeProvider } from '@material-ui/core';

import { TSignupFormValues } from '../components/SignupForm/SignupForm';
import { TSiteCreationDto } from '../models/TSiteCreationDto';
import { toLanguageCodeOrDefault } from '../models/languageCode';

import timezoneJSON from '../data/timezones.json';
import usaJSON from '../data/subnationalDivisions/usa.json';
import industriesJSON from '../data/industries.json';

import * as localizationKeys from './localizationKeys';
import { Link } from '../components/Link/Link';
import { Localizer } from '../components/Localizer/Localizer';
import { TSiteCreationErrorDto } from '../models/TSiteCreationErrorDto';
import { defaultTheme } from '../styles/defaultTheme';

export function getIndustries(
  siteCreationIndustries: Record<string, string> = industriesJSON,
): Array<{
  key: string;
  value: string;
  label: string;
}> {
  // Maps over industries and then splits list of values (labels) by the "/" then flattens the array
  // This is used for the Communications label
  const industryOptions = Object.entries(siteCreationIndustries).map(([key, label]) => {
    return {
      key,
      value: key,
      label,
    };
  });

  return industryOptions;
}

export function getSubnationalDivisionsOptions(
  subnationalDivisions: { [key: string]: string } = usaJSON,
): Array<{
  key: string;
  value: string;
  label: string;
}> {
  const subnationalDivisionsObj = subnationalDivisions as { [key: string]: string };

  return Object.keys(subnationalDivisionsObj).map((key) => {
    return {
      key,
      value: key,
      label: subnationalDivisionsObj[key],
    };
  });
}

export function getTimezones(timezonesArr: string[] = timezoneJSON): Array<{
  key: string;
  value: string;
  label: string;
}> {
  return timezonesArr.map((key) => {
    return { key, value: key, label: key };
  });
}

export function formatFormValuesToSiteCreationDTO(
  values: TSignupFormValues,
  invitationId: string,
): TSiteCreationDto {
  return {
    invitationId: invitationId,
    site: {
      key: values.siteKey,
      name: values.orgName,
      settings: {
        cultureSettings: {
          timeZoneId: values.timezone,
          defaultLanguage: toLanguageCodeOrDefault(navigator.language),
        },
      },
    },
    user: {
      username: values.username,
      password: values.password,
      givenName: values.firstName,
      surname: values.lastName,
      email: values.email,
    },
    organization: {
      name: values.orgName,
      employeeCount: values.numOfEmployees,
      contactPhoneNumber: values.phoneNumber,
      industry: values.industry,
      subnationalDivision: values.orgState,
    },
  };
}

export function getStateDataForErrorCode(
  error: TSiteCreationErrorDto,
  loginUrl: string,
): { errorMessage: JSX.Element; userCanRecover: boolean } {
  switch (error.code) {
    case 'content_hub_invitation_not_found':
    case 'invalid_invitation':
    case 'invitation_already_accepted':
    case 'expired_invitation':
      return {
        errorMessage: <Localizer translation={localizationKeys.InvitationAcceptanceError} />,
        userCanRecover: false,
      };
    case 'user_associated_with_existing_site':
      return {
        errorMessage: (
          <>
            <Localizer translation={localizationKeys.UserAlreadyExistsError} />
            &nbsp;
            <Link href={loginUrl} target="_blank" monochrome="black">
              <Localizer translation={localizationKeys.UserAlreadyExistsErrorVisitLogin} />
            </Link>
          </>
        ),
        userCanRecover: false,
      };
    // eslint-disable-next-line
    // @ts-ignore typescript is not listening to my fallthrough comment :(
    case 'entity_validation_error': {
      const siteKeyValidationError = error.errors.find(
        (validationError) => validationError.code === 'site_key_already_exists',
      );

      if (siteKeyValidationError !== undefined) {
        return {
          errorMessage: <Localizer translation={localizationKeys.SignUpForm_SiteKeyUnavailable} />,
          userCanRecover: true,
        };
      }
    }
    // else, falls through to default (comment is required to tell eslint that it's intentional)
    default:
      return {
        errorMessage: (
          <Localizer
            translation={localizationKeys.GenericApiError}
            trustedHtml={{
              phoneNumber: renderToString(
                <ThemeProvider theme={defaultTheme}>
                  <Link href="tel:18007495104" monochrome="black">
                    1-800-749-5104
                  </Link>
                </ThemeProvider>,
              ),
              email: renderToString(
                <ThemeProvider theme={defaultTheme}>
                  <Link href="mailto:support@powerdms.com" monochrome="black">
                    support@powerdms.com
                  </Link>
                </ThemeProvider>,
              ),
            }}
          />
        ),
        userCanRecover: true,
      };
  }
}

export function constructLoginUrl(
  email: string,
  invitationId: string,
  invitationPublisher: string,
): string {
  const loginUrl = new URL(`${window.env.POWERDMS_BASE_URL}/ui/login.aspx`);

  // Pass any relevant query params we have along to the main app.
  if (email) {
    loginUrl.searchParams.append('recipient_email', email);
  }
  if (invitationId) {
    loginUrl.searchParams.append('content_hub_invite', invitationId);
  }
  if (invitationPublisher) {
    loginUrl.searchParams.append('publisher', invitationPublisher);
  }

  return loginUrl.toString();
}
