import * as React from 'react';
import MaterialTypography, { TypographyProps } from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { TDefaultTheme } from '../../styles/defaultTheme';
import { Palette } from '../../styles/palette';

type TVariance = TypographyProps['variant'] | 'buttonCompact';
export type TTypographyProps = Omit<TypographyProps, 'color' | 'variant'> & {
  color?: 'initial' | 'inherit' | Palette;
  variant?: TVariance;
  /**
   * A hack to get around difficulty in typing component prop for a wrapper component. This hack
   * forgoes compile-time type-safety. React will still warn in development mode if an invalid HTML
   * tag is rendered. If there's a better way to handle this in the future, please revise.
   * More context: https://github.com/mui-org/material-ui/issues/15827
   */
  component?: string;
  ellipse?: boolean;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
};

const useStyles = makeStyles<TDefaultTheme, TTypographyProps>((theme) => ({
  buttonCompact: { ...theme.typography.buttonCompact },
  textColor: {
    color: (props: TTypographyProps): TTypographyProps['color'] => props.color,
  },
  paragraph: {
    lineHeight: 1.25,
  },
  ellipse: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    display: 'inline-block',
  },
  bold: {
    fontWeight: 700,
  },
  italic: {
    fontStyle: 'italic',
  },
  underline: {
    textDecoration: 'underline',
  },
}));

export function mapCustomVariantToMaterial(variant: TVariance): TypographyProps['variant'] {
  /**
   * `button` is the closest to `buttonCompact` so we'll use that as a base and cascade a style
   * sheet on top of it for styling.
   */
  return variant === 'buttonCompact' ? 'button' : variant;
}

export const Typography = (props: TTypographyProps): JSX.Element => {
  const { color, ellipse, paragraph, bold, italic, underline, variant = 'body1', ...rest } = props;

  const styles = useStyles(props);

  const className = clsx({
    [styles.textColor]: color,
    [styles.buttonCompact]: variant === 'buttonCompact',
    [styles.ellipse]: ellipse,
    [styles.paragraph]: paragraph,
    [styles.bold]: bold,
    [styles.italic]: italic,
    [styles.underline]: underline,
  });

  return (
    <MaterialTypography
      className={className}
      variant={mapCustomVariantToMaterial(variant)}
      {...rest}
    />
  );
};
