import React, { FC, ReactElement, useLayoutEffect } from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Importing from 'clsx' doesn't work with Jest. Maybe because diff types of module (commonjs vs es)
import clsx from 'clsx';

type TPageProps = {
  className: string;
  children: ReactElement[];
};

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(var(--vh, 1vh) * 100)',
    fallbacks: {
      minHeight: '100vh',
    },
  },
});

export const Page: FC<TPageProps> = (props: TPageProps) => {
  const { className, children } = props;
  const styles = useStyles(props);
  const classNames = clsx(styles.page, { [className]: className });

  useLayoutEffect(() => {
    /**
     * To work-around default mobile browsers behavior (auto-show/hide address bar), we're
     * "redefining" what 100vh means. We only need to do this on initial load as agreed upon with
     * product (no need to adjust for resize or orientation-change event).
     *
     * See for more info:
     * - https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
     * - https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
     * - https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
     */

    const maxViewportHeight = document.documentElement.clientHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${maxViewportHeight}px`);
  }, []);

  return (
    <Container className={classNames} maxWidth={false} disableGutters={true}>
      {children}
    </Container>
  );
};
