import React from 'react';

import * as localizationKeys from './../services/localizationKeys';
import LocalizationService from './../services/localizationService';

type TUseSiteKeyAvailabilityProps = {
  siteKeyTouched: boolean | undefined;
  siteKeyFormHelperText: string;
  siteKeyFormErrState: boolean | undefined;
};

type TUseSiteKeyAvailabilityReturnType = {
  siteKeyAvailable: boolean | undefined;
  setSiteKeyAvailable: (value: boolean | undefined) => void;
  shouldShowSiteKeyAvailabilityIndicator: boolean | undefined;
  siteKeyIsError: boolean | undefined;
  siteKeyHelperText: string;
};

export function useSiteKeyAvailability({
  siteKeyTouched,
  siteKeyFormHelperText,
  siteKeyFormErrState,
}: TUseSiteKeyAvailabilityProps): TUseSiteKeyAvailabilityReturnType {
  const [siteKeyAvailable, setSiteKeyAvailable] = React.useState<boolean | undefined>(undefined);

  function getSiteKeyHelperText(): string {
    if (siteKeyFormHelperText !== ' ') {
      return siteKeyFormHelperText;
    }

    if (siteKeyTouched && siteKeyAvailable === false) {
      return LocalizationService.localize(localizationKeys.SignUpForm_SiteKeyUnavailable, {});
    }

    return LocalizationService.localize(localizationKeys.SignUpForm_SiteKeyHelperText, {});
  }

  function getSiteKeyErrorState(): boolean | undefined {
    return siteKeyFormErrState || (siteKeyTouched && siteKeyAvailable === false);
  }

  function showSiteKeyAvailabilityIndicator(): boolean | undefined {
    return siteKeyTouched && siteKeyFormHelperText === ' ';
  }

  return {
    siteKeyAvailable,
    setSiteKeyAvailable,
    siteKeyHelperText: getSiteKeyHelperText(),
    siteKeyIsError: getSiteKeyErrorState(),
    shouldShowSiteKeyAvailabilityIndicator: showSiteKeyAvailabilityIndicator(),
  };
}
