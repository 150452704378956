import * as React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

import * as localizationKeys from '../../services/localizationKeys';
import LocalizationService from '../../services/localizationService';

import { TDefaultTheme } from '../../styles/defaultTheme';
import { Palette } from '../../styles/palette';
import { Typography } from '../Typography/Typography';

export type TMessageBoxProps = {
  children?: React.ReactNode;
  id?: string;
  isDismissable?: boolean;
  messageStyle?: 'default' | 'error' | 'info' | 'success' | 'warning';
  onDismiss?: () => void;
};

type TStylingProps = {
  isDismissed: boolean;
  messageStyle: TMessageBoxProps['messageStyle'];
};

export function getMessageColor(
  theme: TDefaultTheme,
  messageStyle: TMessageBoxProps['messageStyle'],
): string {
  switch (messageStyle) {
    case 'error':
      return theme.palette.error.lighter;
    case 'success':
      return theme.palette.success.lighter;
    case 'info':
      return theme.palette.primary.lighter;
    case 'warning':
      return theme.palette.warning.lighter;
    default:
      return theme.palette.gray.lighter;
  }
}

const useStyles = makeStyles<TDefaultTheme, TStylingProps>((theme: TDefaultTheme) => ({
  root: {
    backgroundColor: ({ messageStyle }) => getMessageColor(theme, messageStyle),
    borderRadius: 4,
    padding: theme.spacing(2),
    display: ({ isDismissed }) => (isDismissed ? 'none' : 'flex'),
    flexDirection: 'row',
  },
  content: {
    margin: `1px ${theme.spacing(2)}px`,
    flex: '1 1 100%',
  },
  iconButtonRoot: {
    verticalAlign: 'top',
    marginTop: '-3px',
  },
}));

export function MessageBox(props: TMessageBoxProps): JSX.Element {
  const { isDismissable = false, messageStyle = 'default', onDismiss, children, ...rest } = props;

  const [isDismissed, setIsDismissed] = React.useState(false);

  const styles = useStyles({ isDismissed, messageStyle });

  function onDismissMessage(): void {
    setIsDismissed(true);

    if (onDismiss) {
      onDismiss();
    }
  }

  function IconByStyle(): JSX.Element {
    switch (messageStyle) {
      case 'info':
        return <InfoIcon htmlColor={Palette.primaryDark} data-testid="messageBox_infoIcon" />;
      case 'error':
        return <WarningIcon htmlColor={Palette.danger} data-testid="messageBox_warningIcon" />;
      case 'warning':
        return <WarningIcon htmlColor={Palette.attention} data-testid="messageBox_warningIcon" />;
      case 'success':
        return <CheckCircleIcon htmlColor={Palette.positive} data-testid="messageBox_checkIcon" />;
      default:
        return <InfoIcon htmlColor={Palette.grayDarker} data-testid="messageBox_infoIcon" />;
    }
  }

  return (
    <div className={styles.root} data-testid="messageBox" {...rest}>
      <IconByStyle />
      <Typography className={styles.content}>
        <span>{children}</span>
      </Typography>
      {isDismissable && (
        <span data-testid="messageBox_dismiss">
          <IconButton
            classes={{ root: styles.iconButtonRoot }}
            onClick={onDismissMessage}
            aria-label={LocalizationService.localize(localizationKeys.Dismiss, {})}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </span>
      )}
    </div>
  );
}
