import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { Palette } from './palette';

/**
 * Customizations to MUI's theme to allow us to provide our own properties.
 * See Custom Variables section of docs:
 * https://material-ui.com/customization/theming/#custom-variables
 */
declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    buttonCompact: Typography['button'];
  }

  interface TypographyOptions {
    buttonCompact?: Typography['button'];
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    attention: PaletteColor;
    gray: PaletteColor;
    white: string;
    black: string;
    darkText: {
      main: string;
      light: string;
      lighter: string;
    };
    lightText: {
      main: string;
      dark: string;
      darker: string;
    };
  }

  interface PaletteOptions {
    attention?: PaletteColorOptions;
    gray?: PaletteColorOptions;
    white?: string;
    black?: string;
    darkText?: {
      main: string;
      light: string;
      lighter: string;
    };
    lightText?: {
      main: string;
      dark: string;
      darker: string;
    };
  }

  interface PaletteColor {
    lighter: string;
    darker: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
    darker?: string;
  }
}

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 605,
      lg: 960,
      xl: 1280,
    },
  },
  palette: {
    primary: {
      lighter: Palette.primaryLighter,
      light: Palette.primaryLight,
      main: Palette.primary,
      dark: Palette.primaryDark,
      darker: Palette.primaryDarker,
    },
    secondary: {
      lighter: Palette.secondaryLighter,
      light: Palette.secondaryLight,
      main: Palette.secondary,
      dark: Palette.secondaryDark,
      darker: Palette.secondaryDarker,
    },
    // corresponds to Positive in main app/pattern library
    success: {
      lighter: Palette.positiveLighter,
      main: Palette.positive,
      dark: Palette.positiveDark,
    },
    warning: {
      lighter: Palette.warningLighter,
      main: Palette.warning,
    },
    attention: {
      main: Palette.attention,
      dark: Palette.attentionDark,
    },
    // corresponds to Danger in main app/pattern library
    error: {
      lighter: Palette.dangerLighter,
      main: Palette.danger,
      dark: Palette.dangerDark,
    },
    white: Palette.white,
    black: Palette.black,
    gray: {
      lighter: Palette.grayLighter,
      light: Palette.grayLight,
      main: Palette.gray,
      dark: Palette.grayDark,
      darker: Palette.grayDarker,
    },
    darkText: {
      main: Palette.darkText,
      light: Palette.darkTextLight,
      lighter: Palette.darkTextLighter,
    },
    lightText: {
      main: Palette.lightText,
      dark: Palette.lightTextDark,
      darker: Palette.lightTextDarker,
    },
  },
  typography: {
    allVariants: {
      color: Palette.darkText,
    },
    h1: {
      fontSize: '2.5rem',
      lineHeight: 1,
      fontWeight: 300,
      letterSpacing: '-0.01em',
    },
    h2: {
      fontSize: '2.25rem',
      lineHeight: 1.143,
      fontWeight: 400,
      letterSpacing: '-0.01em',
    },
    h3: {
      fontSize: '2rem',
      lineHeight: 1.167,
      fontWeight: 400,
      letterSpacing: '-0.01em',
    },
    h4: {
      fontSize: '1.75rem',
      lineHeight: 1.2,
      fontWeight: 400,
      letterSpacing: '-0.008em',
    },
    h5: {
      fontSize: '1.5rem',
      lineHeight: 1.25,
      fontWeight: 400,
      letterSpacing: '-0.006em',
    },
    h6: {
      fontSize: '1.25rem',
      lineHeight: 1.143,
      fontWeight: 400,
      letterSpacing: '-0.003em',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.2,
      fontWeight: 400,
      letterSpacing: '0em',
    },
    body2: {
      fontSize: '1.375rem',
      lineHeight: 1.275,
      fontWeight: 400,
      letterSpacing: '-0.003em',
    },
    button: {
      fontSize: '0.875rem',
      lineHeight: 1.429,
      fontWeight: 700,
      letterSpacing: '0.02857em',
      textTransform: 'none',
    },
    buttonCompact: {
      fontSize: '0.8125rem',
      lineHeight: 1.231,
      fontWeight: 700,
      letterSpacing: '0.02857em',
      textTransform: 'none',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.333,
      fontWeight: 400,
      letterSpacing: '0em',
    },
    overline: {
      fontSize: '0.75rem',
      lineHeight: 1.333,
      fontWeight: 700,
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
    },
  },
});

export const defaultTheme = responsiveFontSizes(theme);

export type TDefaultTheme = typeof defaultTheme;
