import { useEffect } from 'react';
import { FormikHelpers } from 'formik';
import { TSignupFormValues } from '../components/SignupForm/SignupForm';

export type TPasswordRequirements = {
  eightCharsOrMore: boolean;
  upperAndLowercase: boolean;
  oneNumberOrMore: boolean;
  noPersonalInfo: boolean;
};

export function usePasswordRequirements(
  values: TSignupFormValues,
  setValues: FormikHelpers<TSignupFormValues>['setValues'],
): boolean {
  const { password, username } = values;

  useEffect(() => {
    setValues(
      {
        ...values,
        eightCharsOrMore: hasEightCharsOrMore(password),
        upperAndLowercase: hasUpperAndLowercase(password),
        oneNumberOrMore: hasOneNumberOrMore(password),
        noPersonalInfo: hasNoPersonalInfo(password, username),
      },
      true,
    );
  }, [password, username]);

  return (
    !values.eightCharsOrMore ||
    !values.upperAndLowercase ||
    !values.oneNumberOrMore ||
    !values.noPersonalInfo
  );
}

export function hasEightCharsOrMore(password: string): boolean {
  return /.{8,}/gi.test(password);
}

export function hasUpperAndLowercase(password: string): boolean {
  return /[a-z]/g.test(password) && /[A-Z]/g.test(password);
}

export function hasOneNumberOrMore(password: string): boolean {
  return /\d+/g.test(password);
}

export function hasNoPersonalInfo(password: string, username: string): boolean {
  return password === '' || username === '' || !new RegExp(username, 'ig').test(password);
}
