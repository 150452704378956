import * as React from 'react';

import LocalizationService, { TTranslation } from './../../services/localizationService';
import { escapeHtml } from '../../services/sanitizationService';

export type TLocalizerProps = {
  className?: string;
  translation: TTranslation;
  values?: { [key: string]: string };
  trustedHtml?: { [key: string]: string };
};

export const Localizer: React.FunctionComponent<TLocalizerProps> = ({
  className,
  translation,
  values = {},
  trustedHtml = {},
  ...rest
}) => {
  const safeValues = Object.keys(values).reduce(
    (safeValuesAccumulator, key) => ({
      ...safeValuesAccumulator,
      [key]: escapeHtml(values[key]),
    }),
    {},
  );

  return (
    <span
      {...rest}
      className={className}
      dangerouslySetInnerHTML={{
        __html: LocalizationService.localize(translation, { ...safeValues, ...trustedHtml }),
      }}
    />
  );
};
