/* eslint-disable @typescript-eslint/ban-ts-comment */
import { RumEvent } from '@datadog/browser-rum';

const queryStringRedactRegEx = /([?&]{1}[a-zA-Z0-9_]+)=([[\]{}%\w:@.?$+!;" =*`()~|,+:/-]+)/g;

export function pteREDACTyl(event: RumEvent): void {
  if (event.view) {
    event.view.url = event.view.url.replace(queryStringRedactRegEx, '$1=[redacted]');
    event.view.referrer = event.view.referrer?.replace(queryStringRedactRegEx, '$1=[redacted]');
  }

  if (event.resource) {
    // @ts-ignore: error unknown
    event.resource.url = event.resource.url.replace(queryStringRedactRegEx, '$1=[redacted]');
  }

  if (!event.error) {
    return;
  }

  // @ts-ignore: error unknown
  if (event.error.resource) {
    // @ts-ignore: error unknown
    event.error.resource.url = event.error.resource.url.replace(
      queryStringRedactRegEx,
      '$1=[redacted]',
    );
  }

  // @ts-ignore: error unknown
  if (event.error.stack) {
    // @ts-ignore: error unknown
    event.error.stack = event.error.stack.replace(queryStringRedactRegEx, '$1=[redacted]');
  }

  // @ts-ignore: error unknown
  if (event.error.message) {
    // @ts-ignore: error unknown
    event.error.message = event.error.message.replace(queryStringRedactRegEx, '$1=[redacted]');
  }
}
