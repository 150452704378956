import { makeStyles } from '@material-ui/styles';
import { TDropdownFieldProps } from '../components/DropdownField/DropdownField';
import { TTextFieldProps } from '../components/TextField/TextField';

import { Palette } from './palette';
import { defaultTheme as Theme } from './defaultTheme';

type TInputStylesProps = {
  readOnly: boolean;
  isMultilineResizable: boolean;
};

export const inputStyles = makeStyles<TInputStylesProps, TDropdownFieldProps | TTextFieldProps>(
  () => ({
    input: {
      backgroundColor: ({ readOnly }) => (readOnly ? Palette.grayLighter : 'transparent'),
      border: `1px solid ${Palette.gray}`,
      // @TODO: This will stay as darkText(.95)
      borderRadius: 4,
      color: Palette.darkText,
      fontSize: '0.875rem',
      lineHeight: 1.2,
      transition: 'border-color 0.2s ease-in-out 0s',
      '&:hover': {
        borderColor: Palette.grayDarker,
      },
      '& input': {
        fontFamily: Theme.typography.fontFamily,
        height: 'auto',
        padding: Theme.spacing(0.5),
      },
      '& select': {
        paddingLeft: Theme.spacing(0.5),
        '&:focus': {
          backgroundColor: ({ readOnly }) => (readOnly ? Palette.grayLighter : 'transparent'),
        },
      },
      '& > option': {
        padding: Theme.spacing(0.5),
      },
    },
    inputMultiline: {
      padding: Theme.spacing(0.5),
      // @TODO: resize: ({ isMultilineResizable = true }) => (isMultilineResizable ? 'vertical' : 'none'),
      resize: 'none',
      /**
       * Multiline textareas in Firefox have an extra row, which we want to prevent:
       * https://stackoverflow.com/questions/46746887/textarea-extra-row-in-firefox-browser/47494019
       */
      overflowX: 'hidden',
    },
    focused: {
      borderColor: Palette.primary,
      transition: 'border-color 0.2s ease-in-out 0s',
      boxShadow: `0px 0px 2px 1px ${Palette.primaryLight}`,
    },
    error: {
      borderColor: Palette.danger,
    },
    disabled: {
      // @TODO: replace this with the new 0.54 opacity color
      color: Palette.darkTextLight,
      borderColor: Palette.grayLight,
    },
  }),
);
