/* eslint-disable */
// This file is generated by genTypedKeys.js. Do not edit manually.

export class AlreadyHaveAccount {
  static readonly key: string = "AlreadyHaveAccount";
}
export class CreateYourAccount {
  static readonly key: string = "CreateYourAccount";
}
export class Dismiss {
  static readonly key: string = "Dismiss";
}
export class DownloadApp_AppleBadgeAlt {
  static readonly key: string = "DownloadApp.AppleBadgeAlt";
}
export class DownloadApp_Copy {
  static readonly key: string = "DownloadApp.Copy";
  currentYear!: string;
}
export class DownloadApp_GetTheApp {
  static readonly key: string = "DownloadApp.GetTheApp";
}
export class DownloadApp_GoogleBadgeAlt {
  static readonly key: string = "DownloadApp.GoogleBadgeAlt";
}
export class DownloadApp_SignedUp {
  static readonly key: string = "DownloadApp.SignedUp";
}
export class DownloadApp_VisitPowerDMS {
  static readonly key: string = "DownloadApp.VisitPowerDMS";
}
export class GenericApiError {
  static readonly key: string = "GenericApiError";
  phoneNumber!: string;
  email!: string;
}
export class InternetError {
  static readonly key: string = "InternetError";
}
export class InvitationAcceptanceError {
  static readonly key: string = "InvitationAcceptanceError";
}
export class LogInInstead {
  static readonly key: string = "LogInInstead";
}
export class MetaDescription {
  static readonly key: string = "MetaDescription";
}
export class PowerDMSLogo {
  static readonly key: string = "PowerDMSLogo";
}
export class PrivacyPolicy {
  static readonly key: string = "PrivacyPolicy";
}
export class SignUp {
  static readonly key: string = "SignUp";
}
export class TermsOfUse {
  static readonly key: string = "TermsOfUse";
}
export class UserAlreadyExistsError {
  static readonly key: string = "UserAlreadyExistsError";
}
export class UserAlreadyExistsErrorVisitLogin {
  static readonly key: string = "UserAlreadyExistsErrorVisitLogin";
}
export class MarketingAside_Customer {
  static readonly key: string = "MarketingAside.Customer";
}
export class MarketingAside_DefaultProductDescription {
  static readonly key: string = "MarketingAside.DefaultProductDescription";
}
export class MarketingAside_DefaultProductQuote {
  static readonly key: string = "MarketingAside.DefaultProductQuote";
}
export class MarketingAside_DefaultListItem1PrimaryContent {
  static readonly key: string = "MarketingAside.DefaultListItem1PrimaryContent";
}
export class MarketingAside_DefaultListItem1SecondaryContent {
  static readonly key: string =
    "MarketingAside.DefaultListItem1SecondaryContent";
}
export class MarketingAside_DefaultListItem2PrimaryContent {
  static readonly key: string = "MarketingAside.DefaultListItem2PrimaryContent";
}
export class MarketingAside_DefaultListItem2SecondaryContent {
  static readonly key: string =
    "MarketingAside.DefaultListItem2SecondaryContent";
}
export class SignUpForm_AlphanumericSiteKey {
  static readonly key: string = "SignUpForm.AlphanumericSiteKey";
}
export class SignUpForm_Back {
  static readonly key: string = "SignUpForm.Back";
}
export class SignUpForm_CreatePassword {
  static readonly key: string = "SignUpForm.CreatePassword";
}
export class SignUpForm_CreateUsername {
  static readonly key: string = "SignUpForm.CreateUsername";
}
export class SignUpForm_EightCharsOrMore {
  static readonly key: string = "SignUpForm.EightCharsOrMore";
}
export class SignUpForm_EmailInvalid {
  static readonly key: string = "SignUpForm.EmailInvalid";
  phoneNumber!: string;
  email!: string;
}
export class SignUpForm_EmailLengthInvalid {
  static readonly key: string = "SignUpForm.EmailLengthInvalid";
  phoneNumber!: string;
  email!: string;
}
export class SignUpForm_FirstName {
  static readonly key: string = "SignUpForm.FirstName";
}
export class SignUpForm_HidePassword {
  static readonly key: string = "SignUpForm.HidePassword";
}
export class SignUpForm_Industry {
  static readonly key: string = "SignUpForm.Industry";
}
export class SignUpForm_LastName {
  static readonly key: string = "SignUpForm.LastName";
}
export class SignUpForm_LoginRedirectNotice {
  static readonly key: string = "SignUpForm.LoginRedirectNotice";
}
export class SignUpForm_LoginRedirectNoticeCTA {
  static readonly key: string = "SignUpForm.LoginRedirectNoticeCTA";
}
export class SignUpForm_Next {
  static readonly key: string = "SignUpForm.Next";
}
export class SignUpForm_NoPersonalInfo {
  static readonly key: string = "SignUpForm.NoPersonalInfo";
}
export class SignUpForm_NotYou {
  static readonly key: string = "SignUpForm.NotYou";
}
export class SignUpForm_NotYouModalCopy {
  static readonly key: string = "SignUpForm.NotYouModalCopy";
  phoneNumber!: string;
  email!: string;
}
export class SignUpForm_NumberOfEmployees {
  static readonly key: string = "SignUpForm.NumberOfEmployees";
}
export class SignUpForm_NumberOfEmployeesValidation {
  static readonly key: string = "SignUpForm.NumberOfEmployeesValidation";
}
export class SignUpForm_OneNumberOrMore {
  static readonly key: string = "SignUpForm.OneNumberOrMore";
}
export class SignUpForm_OrganizationName {
  static readonly key: string = "SignUpForm.OrganizationName";
}
export class SignUpForm_OrganizationState {
  static readonly key: string = "SignUpForm.OrganizationState";
}
export class SignUpForm_PhoneNumber {
  static readonly key: string = "SignUpForm.PhoneNumber";
}
export class SignUpForm_PhoneNumberInvalid {
  static readonly key: string = "SignUpForm.PhoneNumberInvalid";
}
export class SignUpForm_RequiredField {
  static readonly key: string = "SignUpForm.RequiredField";
}
export class SignUpForm_ShowPassword {
  static readonly key: string = "SignUpForm.ShowPassword";
}
export class SignUpForm_SignUp {
  static readonly key: string = "SignUpForm.SignUp";
}
export class SignUpForm_SiteKey {
  static readonly key: string = "SignUpForm.SiteKey";
}
export class SignUpForm_SiteKeyHelperText {
  static readonly key: string = "SignUpForm.SiteKeyHelperText";
}
export class SignUpForm_SiteKeyUnavailable {
  static readonly key: string = "SignUpForm.SiteKeyUnavailable";
}
export class SignUpForm_StepOf {
  static readonly key: string = "SignUpForm.StepOf";
  currentStep!: string;
  totalSteps!: string;
}
export class SignUpForm_TermsLabelEnd {
  static readonly key: string = "SignUpForm.TermsLabelEnd";
}
export class SignUpForm_TermsLabelStart {
  static readonly key: string = "SignUpForm.TermsLabelStart";
}
export class SignUpForm_TimeZone {
  static readonly key: string = "SignUpForm.TimeZone";
}
export class SignUpForm_TogglePassword {
  static readonly key: string = "SignUpForm.TogglePassword";
}
export class SignUpForm_UpperAndLowercase {
  static readonly key: string = "SignUpForm.UpperAndLowercase";
}
export class SignUpForm_WorkEmailAddress {
  static readonly key: string = "SignUpForm.WorkEmailAddress";
}
