import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';

import * as localizationKeys from '../../services/localizationKeys';

import BackgroundSVG from '../../assets/images/mobile-background-landscape.svg';

import { Typography } from '../Typography/Typography';
import { Localizer } from '../Localizer/Localizer';
import { ReactComponent as PowerDmsLogoWhite } from '../../assets/images/powerdms-logo-white.svg';
import { Page } from '../Page/Page';
import { AppleStoreBadge } from '../AppleStoreBadge/AppleStoreBadge';
import { GooglePlayStoreBadge } from '../GooglePlayStoreBadge/GooglePlayStoreBadge';
import { PageFooter } from '../PageFooter/PageFooter';
import { TDefaultTheme } from '../../styles/defaultTheme';
import { Palette } from '../../styles/palette';

type TDownloadMobileAppProps = {
  showMobileDialog: boolean;
};

const useStyles = makeStyles((theme: TDefaultTheme) => {
  return {
    background: {
      backgroundImage: `url(${BackgroundSVG})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    downloadAppPage: {
      textAlign: 'center',
      padding: theme.spacing(5),
      justifyContent: 'space-between',
    },
    header: {
      width: 200,
      alignSelf: 'center',
    },
    centerItem: {
      display: 'flex',
      justifyContent: 'center',
      margin: `${theme.spacing(3)}px 0px`,
    },
    contentBox: {
      width: 256,
      padding: theme.spacing(3),
    },
    signedUp: {
      marginBottom: theme.spacing(3),
    },
    footerLink: {
      color: Palette.lightText,
    },
    appleBadge: {
      margin: `${theme.spacing(3)}px 0px ${theme.spacing(2)}px 0px`,
    },
    googleBadge: {
      marginBottom: theme.spacing(3),
    },
  };
});

// Tracking links provided by mobile team
const googlePlayLink = '&utm_source=powerdms&utm_medium=web&utm_campaign=site-activation';
const appleStoreLink = '?pt=118172618&ct=site-activation&mt=8';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DownloadMobileApp = (props: TDownloadMobileAppProps) => {
  const styles = useStyles();

  const { showMobileDialog } = props;

  return (
    <Dialog fullScreen open={showMobileDialog}>
      <Box className={styles.background}>
        <Page className={styles.downloadAppPage}>
          <header className={styles.header}>
            <Typography variant="h1">
              <Typography variant="srOnly" id="logoAlt">
                PowerDMS
              </Typography>
              <PowerDmsLogoWhite aria-labelledby="logoAlt" />
            </Typography>
          </header>

          <main className={styles.centerItem}>
            <Paper elevation={6} className={styles.contentBox}>
              <Typography variant="body1" className={styles.signedUp}>
                <Localizer translation={localizationKeys.DownloadApp_SignedUp} />
              </Typography>

              <Typography variant="body1">
                <Localizer translation={localizationKeys.DownloadApp_GetTheApp} />
              </Typography>

              <Box className={styles.appleBadge}>
                <AppleStoreBadge trackingLink={appleStoreLink} />
              </Box>

              <Box className={styles.googleBadge}>
                <GooglePlayStoreBadge trackingLink={googlePlayLink} />
              </Box>
            </Paper>
          </main>

          <PageFooter color={Palette.lightText} linkMonochromeColor="white" />
        </Page>
      </Box>
    </Dialog>
  );
};
