import * as React from 'react';

type THiddenLoginFormProps = {
  password: string;
  publisher: string;
  siteKey: string;
  shouldLogin: boolean;
  username: string;
};

function HiddenLoginForm(props: THiddenLoginFormProps): JSX.Element {
  const formEl = React.useRef<HTMLFormElement>(null);

  const { siteKey, username, password, shouldLogin, publisher } = props;

  const loginUrl = `${window.env.POWERDMS_BASE_URL}/ui/login.aspx?publisher=${publisher}`;

  React.useEffect(() => {
    if (shouldLogin) {
      formEl?.current?.submit();
    }
  }, [shouldLogin]);

  return (
    <form
      // not localizing because nobody will be reading this
      name="Hidden Login"
      data-testid="hiddenLoginForm"
      ref={formEl}
      method="POST"
      action={loginUrl}
    >
      <input type="hidden" name="siteKey" value={siteKey} />
      <input type="hidden" name="userName" value={username} />
      <input type="hidden" name="password" value={password} />
    </form>
  );
}

export { HiddenLoginForm };
