import * as React from 'react';
import { Form, Formik, FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import { Box } from '@material-ui/core';

import * as localizationKeys from '../../services/localizationKeys';
import LocalizationService from '../../services/localizationService';

import { Localizer } from '../Localizer/Localizer';
import { Typography } from '../Typography/Typography';
import { FirstStep } from './FirstStep';
import { SecondStep } from './SecondStep';
import { useStepNavigation } from '../../hooks/useStepNavigation';
import { TPasswordRequirements } from '../../hooks/usePasswordRequirements';
import { FormTitle } from './FormTitle';
import { NavigationButtons } from './NavigationButtons';
import {
  validateFirstStep,
  validateSecondStep,
  getErrorState,
  getHelperText,
} from '../../services/formValidationService';
import { MessageBox } from '../MessageBox/MessageBox';
import { useSiteKeyAvailability } from '../../hooks/useSiteKeyAvailability';
import { TSubnationalDivisions } from '../../models/TSubnationalDivisions';
import { TSiteCreationIndustries } from '../../models/TSiteCreationIndustries';
import { TSiteCreationTimeZoneIds } from '../../models/TSiteCreationTimeZoneIds';
import {
  constructLoginUrl,
  formatFormValuesToSiteCreationDTO,
  getStateDataForErrorCode,
} from '../../services/formService';
import { postSignupFormData } from '../../resources/siteResource';
import { HiddenLoginForm } from '../HiddenLoginForm/HiddenLoginForm';
import { useLoginNoticeTimer } from '../../hooks/useLoginNoticeTimer';
import { Link } from '../Link/Link';
import { DownloadMobileApp } from '../DownloadMobileApp/DownloadMobileApp';
import bowser from 'bowser';
import { sendToGainsightPx } from '../../services/gainsightPxService';

export type TSignupFormProps = {
  initialValues: TSignupFormValues;
  invitationId: string;
  invitationPublisher: string;
};

export type TSignupFormValues = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  orgName: string;
  orgState: '' | TSubnationalDivisions;
  numOfEmployees: number;
  industry: '' | TSiteCreationIndustries;
  timezone: '' | TSiteCreationTimeZoneIds;
  siteKey: string;
  email: string;
  username: string;
  password: string;
  terms: boolean;
} & TPasswordRequirements;

const LAST_STEP = 2;

export function SignupForm(props: TSignupFormProps): JSX.Element {
  const { initialValues, invitationId, invitationPublisher } = props;

  const [loginInfo, setLoginInfo] = React.useState({
    username: '',
    password: '',
    siteKey: '',
    shouldLogin: false,
  });

  const { currentStep, goToNextStep, goToPreviousStep } = useStepNavigation(1, LAST_STEP);
  const { showLoginNotice } = useLoginNoticeTimer(loginInfo.shouldLogin);

  const [submissionErrorState, setSubmissionErrorState] = React.useState({
    canRecover: true,
  });

  const [showMobileDialog, setShowMobileDialog] = React.useState(false);

  const loginUrl = constructLoginUrl(initialValues.email, invitationId, invitationPublisher);

  async function trySubmitForm(
    setStatus: FormikHelpers<TSignupFormValues>['setStatus'],
    setSubmitting: FormikHelpers<TSignupFormValues>['setSubmitting'],
    values: TSignupFormValues,
  ): Promise<void> {
    /**
     * when we call the endpoint, we manage the "status" property, which
     * formik has for representing form state such as API responses/errors
     * https://formik.org/docs/api/formik#status-any
     *
     * while the API call is taking place, set status to `undefined` to remove any prior errors
     */
    setStatus(undefined);
    try {
      const formData = formatFormValuesToSiteCreationDTO(values, invitationId);
      const { error } = await postSignupFormData(formData);

      if (error?.code) {
        const errorData = getStateDataForErrorCode(error, loginUrl);
        setStatus(errorData.errorMessage);
        setSubmissionErrorState({ canRecover: errorData.userCanRecover });
        setSubmitting(false);
      } else {
        const browser = bowser.getParser(window.navigator.userAgent);
        const userOnMobile = browser.getPlatformType(true) !== 'desktop';

        if (userOnMobile) {
          setShowMobileDialog(true);
        }

        sendToGainsightPx({ eventName: 'AutoSiteProvisioningAccountCreated' });

        setLoginInfo({
          username: values.username,
          password: values.password,
          siteKey: values.siteKey,
          shouldLogin: userOnMobile ? false : true,
        });
      }
    } catch (error) {
      setStatus(LocalizationService.localize(localizationKeys.InternetError, {}));
    }
  }

  async function handleSubmit(
    values: TSignupFormValues,
    { setStatus, setSubmitting }: FormikHelpers<TSignupFormValues>,
  ) {
    if (currentStep === LAST_STEP) {
      await trySubmitForm(setStatus, setSubmitting, values);
    } else {
      goToNextStep();
      setSubmitting(false);
    }
  }

  function validateFormikForm(
    values: TSignupFormValues,
  ): FormikErrors<TSignupFormValues> | undefined {
    if (currentStep === 1) {
      return validateFirstStep(values);
    }

    if (currentStep === 2) {
      return validateSecondStep(values);
    }
  }

  return (
    <Box mb={5}>
      <FormTitle currentStep={currentStep} loginUrl={loginUrl} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validateFormikForm}
        validateOnMount={false}
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          handleChange,
          handleBlur,
          isSubmitting,
          touched,
          setTouched,
          setValues,
          status,
        }): JSX.Element => {
          function resetTouchedAndValidateNewStep() {
            const newTouched: FormikTouched<TSignupFormValues> = touched;

            (Object.keys(touched) as (keyof TSignupFormValues)[]).forEach(
              (key) => (newTouched[key] = false),
            );

            setTouched(newTouched);
          }

          React.useEffect(() => {
            resetTouchedAndValidateNewStep();
          }, [currentStep]);

          const { siteKeyAvailable, ...rest } = useSiteKeyAvailability({
            siteKeyTouched: touched['siteKey'],
            siteKeyFormErrState: getErrorState('siteKey', errors, touched),
            siteKeyFormHelperText: getHelperText('siteKey', errors, touched),
          });

          function canSubmit(): boolean {
            if (currentStep === 2) {
              return (
                isValid &&
                dirty &&
                siteKeyAvailable !== false &&
                loginInfo.shouldLogin !== true &&
                submissionErrorState.canRecover
              );
            }

            return isValid && dirty;
          }

          return (
            <Form aria-label={LocalizationService.localize(localizationKeys.SignUpForm_SignUp, {})}>
              {status && (
                <Box mb={5}>
                  <MessageBox messageStyle="error">{status}</MessageBox>
                </Box>
              )}
              {showLoginNotice && (
                <Box mb={5}>
                  <MessageBox messageStyle="info">
                    <Localizer translation={localizationKeys.SignUpForm_LoginRedirectNotice} />
                    &nbsp;
                    <Link
                      href={`${window.env.POWERDMS_BASE_URL}/ui/login.aspx?siteid=${values.siteKey}&publisher=${invitationPublisher}`}
                    >
                      <Localizer translation={localizationKeys.SignUpForm_LoginRedirectNoticeCTA} />
                    </Link>
                  </MessageBox>
                </Box>
              )}
              {currentStep === 1 && (
                <FirstStep
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                />
              )}
              {currentStep === 2 && (
                <SecondStep
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  setValues={setValues}
                  siteKeyHelpers={{
                    siteKeyAvailable,
                    ...rest,
                  }}
                />
              )}
              <NavigationButtons
                backButtonClick={goToPreviousStep}
                canSubmit={canSubmit()}
                currentStep={currentStep}
                isSubmitting={isSubmitting}
                LAST_STEP={LAST_STEP}
              />
            </Form>
          );
        }}
      </Formik>
      <HiddenLoginForm {...loginInfo} publisher={invitationPublisher} />
      <DownloadMobileApp showMobileDialog={showMobileDialog} />

      <Typography align="center" variant="caption" component="p">
        <Localizer
          translation={localizationKeys.SignUpForm_StepOf}
          trustedHtml={{ currentStep: currentStep.toString(), totalSteps: LAST_STEP.toString() }}
        />
      </Typography>
    </Box>
  );
}
