import React from 'react';
import { FormikErrors, FormikHandlers, FormikTouched } from 'formik';
import Grid from '@material-ui/core/Grid';

import * as localizationKeys from '../../services/localizationKeys';

import { DropdownField } from '../DropdownField/DropdownField';
import { FormRowGrid } from '../FormRowGrid/FormRowGrid';
import { Localizer } from '../Localizer/Localizer';
import { TextField } from '../TextField/TextField';

import { TSignupFormValues } from './SignupForm';
import { getErrorState, getHelperText } from '../../services/formValidationService';
import {
  getIndustries,
  getSubnationalDivisionsOptions,
  getTimezones,
} from '../../services/formService';

type TFirstStepProps = {
  errors: FormikErrors<TSignupFormValues>;
  handleBlur: FormikHandlers['handleBlur'];
  handleChange: FormikHandlers['handleChange'];
  touched: FormikTouched<TSignupFormValues>;
  values: TSignupFormValues;
};

const orgStateOptions = getSubnationalDivisionsOptions();
const timezoneOptions = getTimezones();
const industryOptions = getIndustries();

export function FirstStep(props: TFirstStepProps): JSX.Element {
  const { errors, values, handleChange, handleBlur, touched } = props;
  const nameLength = 64; // value received from SalesForce
  const phoneLength = 40; // value received from SalesForce
  const orgLength = 50; // value should match site name, which is 50, even though SalesForce has this max at 255

  return (
    <div data-testid="signUp_firstStep">
      <FormRowGrid>
        <Grid container item xs={12} md={6}>
          <TextField
            id="firstName"
            label={<Localizer translation={localizationKeys.SignUpForm_FirstName} />}
            type="text"
            name="firstName"
            autoComplete="given-name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            error={getErrorState('firstName', errors, touched)}
            helperText={getHelperText('firstName', errors, touched)}
            inputProps={{ maxLength: nameLength }}
            autoFocus
            required
          />
        </Grid>
        <Grid container item xs={12} md={6}>
          <TextField
            id="lastName"
            label={<Localizer translation={localizationKeys.SignUpForm_LastName} />}
            type="text"
            name="lastName"
            autoComplete="family-name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            error={getErrorState('lastName', errors, touched)}
            helperText={getHelperText('lastName', errors, touched)}
            inputProps={{ maxLength: nameLength }}
            required
          />
        </Grid>
      </FormRowGrid>

      <FormRowGrid>
        <Grid container item xs={12}>
          <TextField
            id="phoneNumber"
            label={<Localizer translation={localizationKeys.SignUpForm_PhoneNumber} />}
            type="tel"
            name="phoneNumber"
            autoComplete="tel"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phoneNumber}
            error={getErrorState('phoneNumber', errors, touched)}
            helperText={getHelperText('phoneNumber', errors, touched)}
            inputProps={{ maxLength: phoneLength }}
            required
          />
        </Grid>
      </FormRowGrid>

      <FormRowGrid>
        <Grid container item xs={12}>
          <TextField
            id="orgName"
            label={<Localizer translation={localizationKeys.SignUpForm_OrganizationName} />}
            type="text"
            name="orgName"
            autoComplete="organization"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.orgName}
            error={getErrorState('orgName', errors, touched)}
            helperText={getHelperText('orgName', errors, touched)}
            inputProps={{ maxLength: orgLength }}
            required
          />
        </Grid>
      </FormRowGrid>

      <FormRowGrid>
        <Grid container item xs={12}>
          <DropdownField
            id="orgState"
            label={<Localizer translation={localizationKeys.SignUpForm_OrganizationState} />}
            name="orgState"
            autoComplete="address-level1"
            options={orgStateOptions}
            value={values.orgState}
            error={getErrorState('orgState', errors, touched)}
            helperText={getHelperText('orgState', errors, touched)}
            required
          />
        </Grid>
      </FormRowGrid>

      <FormRowGrid>
        <Grid container item xs={12}>
          <TextField
            id="numOfEmployees"
            label={<Localizer translation={localizationKeys.SignUpForm_NumberOfEmployees} />}
            type="number"
            name="numOfEmployees"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.numOfEmployees}
            error={getErrorState('numOfEmployees', errors, touched)}
            helperText={getHelperText('numOfEmployees', errors, touched)}
            inputProps={{ min: 1, max: 10000000 }}
            required
          />
        </Grid>
      </FormRowGrid>

      <FormRowGrid>
        <Grid container item xs={12} md={6}>
          <DropdownField
            id="industry"
            label={<Localizer translation={localizationKeys.SignUpForm_Industry} />}
            name="industry"
            options={industryOptions}
            value={values.industry}
            error={getErrorState('industry', errors, touched)}
            helperText={getHelperText('industry', errors, touched)}
            required
          />
        </Grid>

        <Grid container item xs={12} md={6}>
          <DropdownField
            id="timezone"
            label={<Localizer translation={localizationKeys.SignUpForm_TimeZone} />}
            name="timezone"
            options={timezoneOptions}
            value={values.timezone}
            error={getErrorState('timezone', errors, touched)}
            helperText={getHelperText('timezone', errors, touched)}
            required
          />
        </Grid>
      </FormRowGrid>
    </div>
  );
}
