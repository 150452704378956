import { TSiteCreationDto } from '../models/TSiteCreationDto';
import { TSiteCreationErrorDto } from '../models/TSiteCreationErrorDto';

export function getSiteKeyAvailability(siteKey: string): Promise<Response> {
  const url = `${window.env.API_BASE_URL}/v1/sites/sitekey/availability/${siteKey}`;

  return fetch(url, { method: 'HEAD' });
}

export async function postSignupFormData(data: TSiteCreationDto): Promise<{
  data: TSiteCreationDto;
  error: TSiteCreationErrorDto | null;
}> {
  const url = `${window.env.API_BASE_URL}/v1/bpa/self-service/sites`;

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  return response.json();
}
