import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import CSSBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core';

import { SignupPage } from './components/SignupPage/SignupPage';

import LocalizationService from './services/localizationService';
import * as localizationKeys from './services/localizationKeys';

import { EllipsisLoader } from './components/EllipsisLoader/EllipsisLoader';

const useStyles = makeStyles(() => ({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
}));

function App(): JSX.Element {
  const styles = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    LocalizationService.loadLanguage(navigator.language).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      <CSSBaseline />

      <Helmet>
        <title>{LocalizationService.localize(localizationKeys.SignUp, {})}</title>
        <meta
          name="description"
          content={LocalizationService.localize(localizationKeys.MetaDescription, {})}
        />
      </Helmet>

      {loading && (
        <main className={styles.loading}>
          <EllipsisLoader />
        </main>
      )}

      {!loading && <SignupPage />}
    </>
  );
}

export default App;
