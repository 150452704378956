import React from 'react';
import { makeStyles } from '@material-ui/core';

import * as Keys from '../../services/localizationKeys';

import { Title } from '../Title/Title';
import { Typography } from '../Typography/Typography';
import { Localizer } from '../Localizer/Localizer';
import { Link } from '../Link/Link';
import { TDefaultTheme } from '../../styles/defaultTheme';

type TFormTitleProps = {
  currentStep: number;
  loginUrl: string;
};

const useStyles = makeStyles((theme: TDefaultTheme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(5),
  },
}));

export function FormTitle(props: TFormTitleProps): JSX.Element {
  const { currentStep, loginUrl } = props;

  const styles = useStyles();

  return (
    <div data-testid="signUp_title">
      <div className={styles.title}>
        <Title align="center">
          <Localizer translation={currentStep === 1 ? Keys.SignUp : Keys.CreateYourAccount} />
        </Title>
      </div>
      <div className={styles.subtitle}>
        <Typography align="center" variant="subtitle1" component="p">
          <Localizer translation={Keys.AlreadyHaveAccount} />
          &nbsp;
          <Link href={loginUrl} target="_blank">
            <Localizer translation={Keys.LogInInstead} />
          </Link>
        </Typography>
      </div>
    </div>
  );
}
