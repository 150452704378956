import { useState } from 'react';

export type TUseStepNavigationReturn = {
  currentStep: number;
  goToPreviousStep: () => void;
  goToNextStep: () => void;
};

export function useStepNavigation(startStep: number, lastStep: number): TUseStepNavigationReturn {
  const [currentStep, setCurrentStep] = useState(startStep);

  function goToPreviousStep(): void {
    if (currentStep > 1) {
      setCurrentStep((step) => step - 1);
    }
  }

  function goToNextStep(): void {
    if (currentStep < lastStep) {
      setCurrentStep((step) => step + 1);
    }
  }

  return { currentStep, goToPreviousStep, goToNextStep };
}
