import { getSiteKeyAvailability } from './../resources/siteResource';

export async function isSiteKeyAvailable(siteKey: string): Promise<boolean | undefined> {
  try {
    const response = await getSiteKeyAvailability(siteKey);

    if (response.status === 200) {
      return true;
    }

    /**
     * avoid blocking the form from being submitted (the API will return an error if the sitekey is
     * actually taken)
     */
    if (response.status === 404) {
      return undefined;
    }

    return false;
  } catch (error) {
    console.error(error);
    /**
     * avoid blocking the form from being submitted (the API will return an error if the sitekey is
     * actually taken)
     */
    return undefined;
  }
}
