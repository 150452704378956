export enum Palette {
  primaryLighter = '#E1F4FD',
  primaryLight = '#B3E2FA',
  primary = '#208DCA',
  primaryDark = '#196FB3',
  primaryDarker = '#145191',
  // Changed to accommodate prototype. TBD on whether or not we keep this
  secondaryLighter = '#EFF6FF',
  secondaryLight = '#4D6E90',
  secondary = '#40607F',
  secondaryDark = '#36506E',
  secondaryDarker = '#2C3E50',
  positiveLighter = '#E5FEE2',
  positive = '#048300',
  positiveDark = '#026300',
  warningLighter = '#F6EDCE',
  warning = '#FCB300',
  attention = '#FF5D15',
  attentionDark = '#E14500',
  dangerLighter = '#FDE0E1',
  danger = '#D13C3C',
  dangerDark = '#9C0000',
  white = '#FFFFFF',
  black = '#000000',
  grayLighter = '#F5F5F5',
  grayLight = '#E5E5E5',
  gray = '#CACACA',
  grayDark = '#999999',
  grayDarker = '#666666',
  darkText = 'rgba(0, 0, 0, 0.8)',
  darkTextLight = 'rgba(0, 0, 0, 0.6)',
  darkTextLighter = 'rgba(0, 0, 0, 0.38)',
  lightText = 'rgba(255, 255, 255, 0.97)',
  lightTextDark = 'rgba(255, 255, 255, 0.89)',
  lightTextDarker = 'rgba(255, 255, 255, 0.45)',
}

export type TPaletteTextColors =
  | Palette.darkText
  | Palette.darkTextLight
  | Palette.darkTextLighter
  | Palette.lightText
  | Palette.lightTextDark
  | Palette.lightTextDarker;
