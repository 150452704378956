import { useState, useEffect } from 'react';

export function useLoginNoticeTimer(startLoginTimer: boolean): {
  showLoginNotice: boolean;
} {
  const [showLoginNotice, setShowLoginNotice] = useState(false);

  useEffect(() => {
    if (startLoginTimer) {
      setTimeout(() => {
        setShowLoginNotice(true);
      }, 5000);
    }
  }, [startLoginTimer]);

  return { showLoginNotice };
}
