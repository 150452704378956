import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

import LocalizationService from '../../services/localizationService';
import * as localizationKeys from '../../services/localizationKeys';

import { ReactComponent as AppleBadgeEn } from '../../assets/images/apple-store-badge-en.svg';
import { ReactComponent as AppleBadgeEs } from '../../assets/images/apple-store-badge-es.svg';

type TAppleStoreBadgeProps = {
  trackingLink?: string;
};

const useStyles = makeStyles(() => ({
  largeIcon: {
    fontSize: '9rem',
    fill: 'inherit',
    height: 'inherit',
    width: 'inherit',
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AppleStoreBadge = (props: TAppleStoreBadgeProps) => {
  const styles = useStyles();

  const baseLink = 'https://apps.apple.com/us/app/powerdms-policy-management/id1099254063';
  const link = props.trackingLink ? `${baseLink}${props.trackingLink}` : baseLink;
  const isSpanish = LocalizationService.currentLanguage === 'es';

  return (
    <a href={link}>
      <SvgIcon
        className={styles.largeIcon}
        color="inherit"
        viewBox="0 0 119.664 40"
        titleAccess={LocalizationService.localize(localizationKeys.DownloadApp_AppleBadgeAlt, {})}
        data-testid={isSpanish ? 'apple_es' : 'apple_en'}
        component={isSpanish ? AppleBadgeEs : AppleBadgeEn}
      />
    </a>
  );
};
