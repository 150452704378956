import React from 'react';
import Grid from '@material-ui/core/Grid';

import * as localizationKeys from '../../services/localizationKeys';

import { FormRowGrid } from '../FormRowGrid/FormRowGrid';
import { Button } from '../Button/Button';
import { Localizer } from '../Localizer/Localizer';

type TNavigationButtonsProps = {
  backButtonClick: () => void;
  canSubmit: boolean;
  currentStep: number;
  isSubmitting: boolean;
  LAST_STEP: number;
};

export function NavigationButtons(props: TNavigationButtonsProps): JSX.Element {
  const { backButtonClick, canSubmit, currentStep, isSubmitting, LAST_STEP } = props;

  return (
    <FormRowGrid>
      <Grid container item xs={12}>
        <Button
          fullWidth
          priority="primary"
          effect="save"
          size="regular"
          type="submit"
          disabled={isSubmitting || !canSubmit}
          // ID is for Gainsight PX to track events
          id={currentStep === LAST_STEP ? 'asp_submitForm' : undefined}
        >
          <Localizer
            translation={
              currentStep === LAST_STEP
                ? localizationKeys.SignUpForm_SignUp
                : localizationKeys.SignUpForm_Next
            }
          />
        </Button>
      </Grid>
      {currentStep !== 1 && (
        <Grid container item xs={12}>
          <Button
            fullWidth
            priority="tertiary"
            effect="action"
            size="regular"
            disabled={isSubmitting}
            onClick={backButtonClick}
          >
            <Localizer translation={localizationKeys.SignUpForm_Back} />
          </Button>
        </Grid>
      )}
    </FormRowGrid>
  );
}
