import * as React from 'react';
import MaterialLink, { LinkProps } from '@material-ui/core/Link';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import { TDefaultTheme } from '../../styles/defaultTheme';
import { Palette } from '../../styles/palette';

export type TLinkProps = Omit<
  LinkProps,
  'color' | 'underline' | 'TypographyClasses' | 'variant'
> & {
  disabled?: boolean;
  // Prop is for accessibility on top of background colors
  monochrome?: 'white' | 'black';
  ref?: React.MutableRefObject<HTMLAnchorElement>;
  type?: 'inline' | 'block';
};

export function getColorByProps(props: TLinkProps): { default: Palette; hover: Palette } {
  if (props.disabled) {
    return {
      default: Palette.darkTextLighter,
      hover: Palette.darkTextLighter,
    };
  }

  if (props.monochrome === 'black') {
    return {
      default: Palette.darkText,
      hover: Palette.black,
    };
  }

  if (props.monochrome === 'white') {
    return {
      default: Palette.lightTextDark,
      hover: Palette.white,
    };
  }

  return {
    default: Palette.primaryDark,
    hover: Palette.primaryDarker,
  };
}

type TLinkStyle = {
  color: Palette;
  borderRadius: number;
  pointerEvents: CSSProperties['pointerEvents'];
  padding: number;
  '&:hover, &:focus': {
    color: Palette;
  };
  '&:hover': {
    textDecoration: string;
  };
  '&:focus': {
    outline: string;
    textDecoration: string;
    boxShadow: string;
  };
};

const useStyles = makeStyles<TDefaultTheme, TLinkProps>((theme) => ({
  link: (props): TLinkStyle => {
    const textColor = getColorByProps(props);

    return {
      color: textColor.default,
      borderRadius: 2,
      pointerEvents: props.disabled ? 'none' : 'auto',
      /**
       * When a link isn't part of a sentence, we want to give it some extra padding so that the box
       * shadow isn't so close to the link text
       */
      padding: props.type === 'block' ? 4 : 0,
      '&:hover, &:focus': {
        color: textColor.hover,
      },
      '&:hover': {
        textDecoration: props.disabled ? 'none' : 'underline',
      },
      '&:focus': {
        outline: 'none',
        textDecoration: props.monochrome !== undefined && !props.disabled ? 'underline' : 'none',
        boxShadow: !props.disabled ? `0px 0px 0px 2px ${theme.palette.primary.main}` : 'none',
      },
    };
  },
}));

// eslint-disable-next-line
export const Link = React.forwardRef<HTMLAnchorElement, TLinkProps>((props, ref) => {
  const { monochrome, disabled, className = '', ...rest } = props;

  const { link } = useStyles(props);

  return (
    <MaterialLink
      ref={ref}
      className={`${link} ${className}`}
      underline={!disabled && monochrome ? 'always' : 'hover'}
      tabIndex={props.disabled ? -1 : 0}
      {...rest}
    />
  );
});
