import React, { MouseEvent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { TDefaultTheme } from '../../styles/defaultTheme';
import { Palette } from '../../styles/palette';

import LocalizationService from '../../services/localizationService';
import * as localizationKeys from '../../services/localizationKeys';

type TPasswordVisibilityAdornmentProps = {
  visible: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void | undefined;
  onMouseDown?: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void | undefined;
};

type TClassKeys = 'root';

/* istanbul ignore next */
const useStyles = makeStyles<TDefaultTheme, TPasswordVisibilityAdornmentProps, TClassKeys>(
  (theme: TDefaultTheme) => ({
    root: {
      borderRadius: 4,
      padding: theme.spacing(1),
      border: '2px solid transparent',
      transition: 'color 300ms cubic-bezier(0.86, 0.05, 0.14, 0.93)',
      '&:hover': {
        backgroundColor: 'transparent',
        color: Palette.primary,
      },
      '&:focus': {
        borderColor: Palette.primaryLight,
      },
    },
  }),
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PasswordVisibilityAdornment = (props: TPasswordVisibilityAdornmentProps) => {
  const styles = useStyles(props);

  return (
    <InputAdornment position="end">
      <IconButton
        disableRipple={true}
        disableFocusRipple={true}
        classes={{ root: styles.root }}
        onClick={props.onClick}
        onMouseDown={props.onMouseDown}
        aria-label={
          props.visible
            ? LocalizationService.localize(localizationKeys.SignUpForm_HidePassword, {})
            : LocalizationService.localize(localizationKeys.SignUpForm_ShowPassword, {})
        }
        title={LocalizationService.localize(localizationKeys.SignUpForm_TogglePassword, {})}
      >
        {props.visible ? (
          <VisibilityOff className="passwordVisibility_visibleOffIcon" />
        ) : (
          <Visibility className="passwordVisibility_visibleIcon" />
        )}
      </IconButton>
    </InputAdornment>
  );
};
